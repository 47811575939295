/******************************
 * GENERATED FILE, DO NOT EDIT *
 *******************************/ import type {
  ReactNode,
  SVGProps,
} from "react";
export default function IconHouses(props: SVGProps<SVGSVGElement>): ReactNode {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16px"
      height="16px"
      fill="none"
      viewBox="0 0 26 26"
      color="currentColor"
      {...props}
    >
      <path
        fill="currentColor"
        fillRule="evenodd"
        d="M10.565 3.32c-.544 0-1.055.168-1.467.46l-.005.004-2.749 1.99-4.869 3.508a2.56 2.56 0 0 0-1.08 2.088v8.76a2.546 2.546 0 0 0 2.55 2.55h5.66a.6.6 0 0 0 .6-.6v-3.91c0-.749.601-1.35 1.35-1.35s1.35.601 1.35 1.35v3.91a.6.6 0 0 0 .6.6h5.66a2.546 2.546 0 0 0 2.55-2.55v-2.57h2.89c1.101 0 2-.899 2-2V9.27c0-.657-.316-1.255-.836-1.635l-.003-.001-5.47-3.95-.008-.007a2 2 0 0 0-1.153-.357c-.421 0-.821.127-1.153.357l-.009.007-2.547 1.843-2.3-1.664a.6.6 0 0 0-.101-.087 2.56 2.56 0 0 0-1.46-.456m4.886 2.947 4.182 3.014.002.001c.674.481 1.08 1.251 1.08 2.088v4.99h2.89c.439 0 .8-.361.8-.8V9.27a.82.82 0 0 0-.343-.665L18.598 4.66a.8.8 0 0 0-.464-.14.8.8 0 0 0-.463.14zM10.565 4.52c.252 0 .483.067.687.186q.03.026.061.05l2.75 1.99h.001l4.87 3.51.003.003c.365.26.578.669.578 1.111v8.76c0 .749-.601 1.35-1.35 1.35h-5.06v-3.31a2.546 2.546 0 0 0-2.55-2.55 2.546 2.546 0 0 0-2.55 2.55v3.31h-5.06c-.749 0-1.35-.601-1.35-1.35v-8.76c0-.442.213-.852.578-1.111l.003-.002 4.87-3.51 2.748-1.99c.208-.146.476-.237.77-.237"
        clipRule="evenodd"
      />
    </svg>
  );
}
