import type ModuleSearchConfig from "@/layout/header/searchBar/model/search/ModuleSearchConfig";
import { SearchI18nKeyComposer } from "@/layout/header/searchBar/model/search/SearchesI18N";
import { ModuleSearchDataType } from "@/layout/header/searchBar/search-panel/global-search/module-search/ModuleSearchDataType";
import { GlobalSearchContext } from "@/layout/header/searchBar/GlobalSearchContext";
import { useContext, type ReactNode } from "react";
import { useTranslation } from "react-i18next";

interface Properties {
  searchConfig: ModuleSearchConfig;
}

export function ModuleSearch(props: Properties): ReactNode {
  const { t, i18n } = useTranslation("globalSearch");
  const i18nKeys = new SearchI18nKeyComposer(i18n.exists);
  const { userInput } = useContext(GlobalSearchContext);
  return (
    <div>
      <div>
        <h1>{t(i18nKeys.moduleTitle(props.searchConfig))}</h1>
        <ul>
          {props.searchConfig.searches.map((search) => (
            <ModuleSearchDataType
              key={search.searchType}
              searchConfig={search}
              userInput={userInput}
            />
          ))}
        </ul>
      </div>
    </div>
  );
}
