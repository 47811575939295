/******************************
 * GENERATED FILE, DO NOT EDIT *
 *******************************/ import type {
  ReactNode,
  SVGProps,
} from "react";
export default function IconUniversity(
  props: SVGProps<SVGSVGElement>
): ReactNode {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16px"
      height="16px"
      viewBox="0 0 16 16"
      color="currentColor"
      {...props}
    >
      <path
        d="m8 .637 7.89 3.155v1.052h-1.053q0 .214-.168.37-.169.156-.399.156H1.73q-.23 0-.399-.156-.168-.156-.168-.37H.11V3.792Zm-5.785 5.26h2.103v6.31H5.37v-6.31h2.104v6.31h1.052v-6.31h2.104v6.31h1.052v-6.31h2.103v6.31h.485q.23 0 .399.157.168.156.168.37v.525H1.163v-.526q0-.213.168-.37.169-.155.399-.155h.485Zm13.107 7.888q.23 0 .399.157.168.156.168.37v1.051H.111v-1.052q0-.213.168-.37.169-.156.399-.156z"
        fill="currentColor"
      />
    </svg>
  );
}
