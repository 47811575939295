import { StrictMode } from "react";
import { createRoot } from "react-dom/client";

import App from "@/App.tsx";
import "@/shared/style/index.css";

// Fonts --------------------------------------
import "@fontsource/source-serif-pro/400.css";
import "@fontsource/source-serif-pro/600.css";
import "@fontsource/source-serif-pro/400-italic.css";
import "@fontsource/source-serif-pro/600-italic.css";
// Supports weights 100 to 900:
import "@fontsource-variable/lexend-deca";

const rootElement = document.getElementById("root");
if (rootElement === null) {
  throw new Error("Root element not found");
}

createRoot(rootElement).render(
  <StrictMode>
    <App />
  </StrictMode>,
  /* A note about StrictMode :
    Strict Mode enables the following checks in development:
    - Your components will re-render an extra time to find bugs caused by impure rendering.
    - Your components will re-run Effects an extra time to find bugs caused by missing Effect cleanup.
    - Your components will be checked for usage of deprecated APIs.
    - All of these checks are development-only and do not impact the production build.
    see: https://react.dev/reference/react/StrictMode#strictmode
   */
);
