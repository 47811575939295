export interface Bookmark {
  link: string;
  target: string;
  label: string;
  internal: boolean;
}

export function jsonToBookmark(json: object): Bookmark {
  const obj = json as BookmarkJson;
  return {
    link: prefix(obj.link) + obj.link,
    target: obj.target,
    label: obj.label,
    internal: obj.isInternal,
  };
}

interface BookmarkJson {
  link: string;
  target: string;
  label: string;
  isInternal: boolean;
}

function prefix(url: string): string {
  if (url.startsWith("content/") || url.startsWith("rest/")) {
    return "/";
  }
  return "";
}
