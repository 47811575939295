import { legacyParseFrenchDate } from "@/shared/utils/LegacyDates";
import type { GenericPreferences } from "./GenericPreferences";

export class SitePreferences {
  constructor(readonly rawValues: GenericPreferences) {}

  cyclesTravail(): CycleTravail[] {
    return (
      this.rawValues.parseJson<CycleTravail[]>(
        "com.netresto.rh.core.PERIODES_CYCLES",
        mapCyclesTravail,
      ) ?? []
    );
  }

  conventionCollective(): ConventionCollective | undefined {
    return mapEnum(
      this.rawValues.getString("com.netresto.rh.core.CONVENTION_COLLECTIVE"),
      conventionsMappings,
    );
  }

  isRCREnabled(): boolean {
    return (
      this.rawValues.parseBoolean("com.netresto.rh.core.ACTIVATE_RCR") ?? false
    );
  }
}

// ------------------------------------------------------------------------------
// Cycles de travail
// ------------------------------------------------------------------------------

export class CycleTravail {
  constructor(
    public startDate: Date,
    public endDate: Date,
  ) {}
}

function mapCyclesTravail(json: unknown): CycleTravail[] {
  return (json as { dateDebut: string; dateFin: string }[]).map(
    (o) =>
      new CycleTravail(
        // eslint-disable-next-line @typescript-eslint/no-deprecated
        legacyParseFrenchDate(o.dateDebut),
        // eslint-disable-next-line @typescript-eslint/no-deprecated
        legacyParseFrenchDate(o.dateFin),
      ),
  );
}

// ------------------------------------------------------------------------------
// Enums definitions
// ------------------------------------------------------------------------------

function mapEnum<V>(
  key: string | undefined,
  map: Map<string, V>,
): V | undefined {
  return key === undefined ? undefined : map.get(key);
}

export enum ConventionCollective {
  HCR,
  SNARR,
  BP,
  AIBP,
  TSF,
}

const conventionsMappings = new Map<string, ConventionCollective>([
  ["com.netresto.rh.conventions.hcr", ConventionCollective.HCR],
  ["com.netresto.rh.conventions.restorapide", ConventionCollective.SNARR],
  ["com.netresto.rh.conventions.bp", ConventionCollective.BP],
  ["com.netresto.rh.conventions.aibp", ConventionCollective.AIBP],
  ["com.netresto.rh.conventions.tsf", ConventionCollective.TSF],
]);
