import ThreeDots from "@/shared/components/generic/loading/ThreeDots";
import { WordsHighlights } from "@/shared/components/generic/text/WordsHighlights";
import type SearchConfig from "@/layout/header/searchBar/model/search/SearchConfig";
import { sortByKey } from "@/shared/utils/Arrays";
import { normalizedKeywords } from "@/shared/utils/Strings";
import { useQuery } from "@tanstack/react-query";
import type { ReactNode } from "react";
import { useTranslation } from "react-i18next";
import { searchQuery } from "./services/SearchApi";
import { SearchI18nKeyComposer } from "../model/search/SearchesI18N";
import "./SearchResult.scss";
import type { ResultRow } from "@/layout/header/searchBar/result-popup/services/dtos/ResultRow";

interface Properties {
  userInput: string;
  searchConfig: SearchConfig;
}

const ROWS_LIMIT = 500;

export function SearchResult(props: Properties): ReactNode {
  const { t, i18n } = useTranslation("globalSearch");
  const i18nKeys = new SearchI18nKeyComposer(i18n.exists);

  const { data, isFetching } = useQuery(
    searchQuery(props.searchConfig.searchType, props.userInput),
  );
  return isFetching ? renderWaiting() : renderResult();

  function renderWaiting(): ReactNode {
    return (
      <div className="waiting">
        <ThreeDots />
      </div>
    );
  }

  function renderResult(): ReactNode {
    return (data ?? []).length > 0 ?
        renderTable()
      : <div className="no-results">{t("common:noResultsFound")}</div>;
  }

  function renderTable(): ReactNode {
    return (
      <table className="result-table">
        {renderHeader()}
        {renderBody()}
      </table>
    );
  }

  function renderHeader(): ReactNode {
    return (
      <thead>
        <tr>
          {props.searchConfig.resultConfig.columns.map((col) => (
            <th key={col.i18nKey}>{t(i18nKeys.columnTitle(col))}</th>
          ))}
        </tr>
      </thead>
    );
  }

  function renderBody(): ReactNode {
    if (data !== undefined) {
      sortByKey(data, (r) => r.matchLevel, true);
    }
    const renderRows = data === undefined ? [] : data.slice(0, ROWS_LIMIT);
    if (
      props.searchConfig.resultConfig.redirectIfSingleResult &&
      renderRows.length === 1 &&
      renderRows[0]?.url !== undefined
    ) {
      window.open(renderRows[0]?.url, "_self");
    }
    const keywords = normalizedKeywords(props.userInput);
    return (
      <tbody>
        {renderRows.map((row, index) => tableRow(row, index, keywords))}
      </tbody>
    );
  }

  function tableRow(
    row: ResultRow,
    rowIndex: number,
    keywords: string[],
  ): ReactNode {
    const url = row.url ?? "";
    let clickHandler: ((e: React.MouseEvent) => void) | undefined = undefined;
    if (url !== "") {
      clickHandler = (e: React.MouseEvent): void => {
        if ([0, 1].includes(e.button)) {
          window.open(url, ["_self", "_blank"][e.button]);
        }
      };
    }
    return (
      <tr
        key={rowIndex}
        className={url !== "" ? "clickable" : ""}
        onClick={clickHandler}
        onAuxClick={clickHandler}
      >
        {row.values.map((v, i) => {
          const value = v === "" ? "-" : v;
          return (
            <td key={i}>
              {props.searchConfig.resultConfig.columns[i].searchInput ?
                <WordsHighlights text={value} normalizedKeywords={keywords} />
              : value}
            </td>
          );
        })}
      </tr>
    );
  }
}
