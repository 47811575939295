import { AppUserInfoContext } from "@/shared/model/user/AppUserInfoContext";
import { type ReactNode, useContext } from "react";
import "@/shared/style/design_system/Fonts.scss";
import { useTranslation } from "react-i18next";
import "./WelcomeMessage.scss";

export default function WelcomeMessage(): ReactNode {
  const { firstName, lastName } = useContext(AppUserInfoContext).userInfo ?? {};
  const { t } = useTranslation(["menus"]);
  return (
    <div className="welcome-message">
      <span>
        <b>
          {t("menus:mainHeader.welcome.hello", {
            firstName,
            lastName,
          })}
        </b>
        {t("menus:mainHeader.welcome.message")}
      </span>
    </div>
  );
}
