/******************************
 * GENERATED FILE, DO NOT EDIT *
 *******************************/ import type {
  ReactNode,
  SVGProps,
} from "react";
export default function IconMagnifier2(
  props: SVGProps<SVGSVGElement>
): ReactNode {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16px"
      height="16px"
      fill="none"
      viewBox="0 0 16 16"
      color="currentColor"
      {...props}
    >
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit={10}
        strokeWidth={1.455}
        d="M10.59 5.659a4.931 4.931 0 1 0-9.863 0 4.931 4.931 0 0 0 9.863 0"
      />
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit={10}
        strokeWidth={1.455}
        d="m9.644 8.564 4.839 3.344a1.852 1.852 0 0 1 .253 2.827 1.85 1.85 0 0 1-2.827-.253L8.565 9.643"
      />
    </svg>
  );
}
