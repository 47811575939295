import { TitleModalPopup } from "@/shared/components/generic/popup/TitleModalPopup";
import type SearchConfig from "@/layout/header/searchBar/model/search/SearchConfig";
import type { ReactNode } from "react";
import { Trans, useTranslation } from "react-i18next";
import { SearchI18nKeyComposer } from "../model/search/SearchesI18N";
import { SearchResult } from "./SearchResult";
import "./SearchResultPopup.scss";
import IconMagnifier from "@/shared/components/generic/icons/IconMagnifier.tsx";
import { colors } from "@/shared/style/design_system/Colors.ts";

export interface Properties {
  onResultClosed: () => void;
  searchConfig: SearchConfig;
  userInput: string;
}

export function SearchResultPop(props: Properties): ReactNode {
  const { i18n } = useTranslation("globalSearch");
  const i18nKeys = new SearchI18nKeyComposer(i18n.exists);

  function buildTitle(): ReactNode {
    return (
      <div className="search-result-title">
        <IconMagnifier width="24px" height="24px" color={colors.lagon} />
        <div>
          <Trans
            i18nKey={i18nKeys.resultTitle(props.searchConfig)}
            values={{ input: props.userInput }}
          />
        </div>
      </div>
    );
  }

  return (
    <TitleModalPopup
      closeWithEscape
      closeWithOutsideClick
      onClose={props.onResultClosed}
      closeButton
      title={buildTitle()}
    >
      <SearchResult
        userInput={props.userInput}
        searchConfig={props.searchConfig}
      />
    </TitleModalPopup>
  );
}
