import type { PropsWithChildren, ReactNode } from "react";
import { useState } from "react";
import "./TitleModalPopup.scss";
import { useKeyboardHandler } from "../hooks/useKeyboardHandler";

export interface Properties {
  closeWithEscape?: boolean;
  closeWithOutsideClick?: boolean;
  onClose?: () => void;
  closeButton?: boolean;
  title: ReactNode;
}

export function TitleModalPopup(
  props: PropsWithChildren<Properties>,
): ReactNode {
  useKeyboardHandler(handleEscapePressed);
  const [closed, setClosed] = useState(false);

  return closed ? undefined : (
      <div className="title-modal-popup" onClick={clickOutside}>
        <div className="dialog" onClick={clickInside}>
          <div className="title-bar">
            {props.title}
            {props.closeButton === true && (
              <div className="buttons-bar">
                <div className="close-button" onClick={handleCloseButton} />
              </div>
            )}
          </div>
          <div className="content">{props.children}</div>
          <div className="bottom-bar"></div>
        </div>
      </div>
    );

  function close(): void {
    setClosed(true);
    props.onClose?.();
  }

  function handleCloseButton(e: React.MouseEvent<HTMLElement>): void {
    e.stopPropagation();
    close();
  }

  function clickOutside(e: React.MouseEvent<HTMLElement>): void {
    e.stopPropagation();
    if (props.closeWithOutsideClick === true) {
      close();
    }
  }

  function clickInside(e: React.MouseEvent<HTMLElement>): void {
    e.stopPropagation();
  }

  function handleEscapePressed(e: KeyboardEvent): void {
    if (e.key === "Escape" && props.closeWithEscape === true) {
      close();
    }
  }
}
