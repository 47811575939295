import * as Arrays from "@/shared/utils/Arrays";
import { legacyParseIsoFrenchDate } from "@/shared/utils/LegacyDates";
import { AppUserInfo, UserEmail, UserInfo } from "./AppUserInfo";
import { GenericPreferences } from "./GenericPreferences";
import { SitePreferences } from "./SitePreferences";
import { Domain, Site } from "./UserDomains";
import {
  UserDomainPermissions,
  UserFlags,
  UserPermissions,
} from "./UserPermissions";
import { UserPreferences } from "./UserPreferences";
import { ModuleSubscription } from "@/shared/model/modules/ModuleSubscription";
import { MyCawanModule } from "@/shared/model/modules/MyCawanModule";

// Key for localStorage data coming from back end:
export const BACKEND_CACHE_KEY = "backend_cache";

// -----------------------------------------------------------------------------
// Structure of backend cache
// Do not export these interfaces, they are only used to clarify legacy
// "JsCache" structure, and used here to create an AppUserInfo instance
// -----------------------------------------------------------------------------

interface JsBackendCache {
  currentUser: JsCurrentUser;
  domains: JsDomain[];
  sites: {
    sites: JsSite[];
    afficheCode: boolean;
    triParCode: boolean;
  };
  sks: Record<string, string[]>; // keys are domain ids as string
  saas: Record<
    string,
    { siteId: number; dateDebut: string; dateFin: string | null }[]
  >;
}

interface JsCurrentUser {
  id: number;
  login: string;
  mail: string;
  mailNewValue: string | null;
  mailVerified: boolean;
  lang: string;
  firstName: string;
  lastName: string;
  fullName: string;
  betaTester: boolean;
  commercial: boolean;
  developer: boolean;
  support: boolean;
}

interface JsDomain {
  id: number;
  isUserAdmin: boolean;
  name: string;
  organizationName: string;
  preferences: Record<string, string>;
}

interface JsSite {
  id: number;
  idDomain: number;
  code: string;
  nom: string;
  isPrefere: boolean;
  preferences: Record<string, string>;
  services: {
    id: number;
    debut: string;
    fin: string;
    text: string;
  }[];
}

export function clearBackendCache(): void {
  localStorage.removeItem(BACKEND_CACHE_KEY);
}

function getBackendCache(): JsBackendCache | undefined {
  const storedItem = localStorage.getItem(BACKEND_CACHE_KEY);
  if (storedItem == null) return undefined;
  return JSON.parse(storedItem) as JsBackendCache;
}

export function buildAppUserInfoFromLocalStorage(): AppUserInfo {
  const backendCache = getBackendCache();
  if (backendCache === undefined) {
    return new AppUserInfo(false);
  }
  return buildAppUserInfoFromObject(backendCache);
}

export function buildAppUserInfoFromObject(obj: object): AppUserInfo {
  const backEndCache = obj as JsBackendCache;
  return new AppUserInfo(
    true,
    buildUserInfo(backEndCache),
    buildCurrentUserPermissions(backEndCache),
    buildUserPreferences(backEndCache),
  );
}

function buildUserInfo(backEndCache: JsBackendCache): UserInfo {
  const user = backEndCache.currentUser;
  return new UserInfo(
    user.id.toString(),
    user.login,
    new UserEmail(user.mail, user.mailNewValue, user.mailVerified),
    user.firstName,
    user.lastName,
    user.lang,
  );
}

function buildCurrentUserPermissions(
  backendCache: JsBackendCache,
): UserPermissions {
  return new UserPermissions(
    buildPermissionsByDomainId(backendCache),
    buildUserFlags(backendCache),
  );
}

function buildPermissionsByDomainId(
  backendCache: JsBackendCache,
): Map<string, UserDomainPermissions> {
  const res = new Map<string, UserDomainPermissions>();

  const subscriptions = buildSiteIdToSubscriptionsMap(backendCache);
  const sites = buildDomainIdToSitesMap(backendCache, subscriptions);

  for (const jsDomain of backendCache.domains) {
    const domainId = jsDomain.id.toString();
    res.set(
      domainId,
      new UserDomainPermissions(
        new Domain(
          domainId,
          jsDomain.organizationName,
          jsDomain.name,
          recordToPreferences(jsDomain.preferences),
          sites.get(domainId) ?? [],
        ),
        jsDomain.isUserAdmin,
        (backendCache.sks[domainId] ?? []).map(shortenPermKey),
      ),
    );
  }
  return res;
}

function shortenPermKey(permKey: string): string {
  return permKey.substring(permKey.lastIndexOf(".") + 1);
}

function buildDomainIdToSitesMap(
  backendCache: JsBackendCache,
  subscriptions: Map<string, ModuleSubscription[]>,
): Map<string, Site[]> {
  // Build map:
  const res = new Map<string, Site[]>();
  for (const site of backendCache.sites.sites) {
    const siteId = site.id.toString();
    const domainId = site.idDomain.toString();
    if (!res.has(domainId)) {
      res.set(domainId, []);
    }
    res
      .get(domainId)
      ?.push(
        new Site(
          domainId,
          siteId,
          site.nom,
          site.code,
          site.isPrefere,
          subscriptions.get(siteId) ?? [],
          new SitePreferences(recordToPreferences(site.preferences)),
        ),
      );
  }
  // Sort sites according to user preference:
  const siteKeyFn: (site: Site) => string =
    backendCache.sites.triParCode ?
      (site): string => site.code
    : (site): string => site.name;
  res.forEach((sites) => {
    Arrays.sortByKey(sites, siteKeyFn);
  });
  return res;
}

function buildSiteIdToSubscriptionsMap(
  backendCache: JsBackendCache,
): Map<string, ModuleSubscription[]> {
  const res = new Map<string, ModuleSubscription[]>();
  Object.entries(backendCache.saas).forEach(([moduleId, sites]) => {
    for (const siteSubscription of sites) {
      const siteIdStr = siteSubscription.siteId.toString();
      if (!res.has(siteIdStr)) {
        res.set(siteIdStr, []);
      }
      res.get(siteIdStr)?.push(
        new ModuleSubscription(
          mapModule(moduleId),
          // eslint-disable-next-line @typescript-eslint/no-deprecated
          legacyParseIsoFrenchDate(siteSubscription.dateDebut),
          siteSubscription.dateFin != null ?
            // eslint-disable-next-line @typescript-eslint/no-deprecated
            legacyParseIsoFrenchDate(siteSubscription.dateFin)
          : undefined,
        ),
      );
    }
  });
  return res;
}

function buildUserFlags(backendCache: JsBackendCache): UserFlags {
  const user = backendCache.currentUser as {
    betaTester: boolean;
    commercial: boolean;
    developer: boolean;
    support: boolean;
  };
  if (user.developer) {
    return UserFlags.createDevelopper();
  } else if (user.support) {
    return UserFlags.createSupport();
  } else if (user.commercial) {
    return UserFlags.createSalesPerson();
  } else if (user.betaTester) {
    return UserFlags.createBetaTester();
  } else {
    return UserFlags.createNoFlags();
  }
}

function buildUserPreferences(backendCache: JsBackendCache): UserPreferences {
  return new UserPreferences(
    backendCache.sites.afficheCode,
    backendCache.sites.triParCode,
  );
}

function recordToPreferences(
  record: Record<string, string>,
): GenericPreferences {
  const map = new Map<string, string>();
  Object.entries(record).forEach(([key, value]) => {
    if (map.has(key)) {
      console.error(`Found duplicate key ${key} in record:`, record);
      throw new Error(`Duplicate key in record: ${key}`);
    }
    map.set(key, value);
  });
  return new GenericPreferences(map);
}

export function mapModule(key: string): MyCawanModule {
  const res = modulesMapping.get(key);
  if (res === undefined) {
    throw Error(`Missing MyCawanModule for key : ${key}`);
  }
  return res;
}

const modulesMapping = new Map<string, MyCawanModule>([
  ["gestion", MyCawanModule.ACHATS_GESTION],
  ["fullsoonapi", MyCawanModule.API_FULLSOON],
  ["francefrais", MyCawanModule.EDI_FRANCE_FRAIS],
  ["prevogel", MyCawanModule.EDI_PREVOGEL],
  ["stef", MyCawanModule.EDI_STEF],
  ["com.netresto.compta.saas.core.compta", MyCawanModule.COMPTABILITE],
  ["fds", MyCawanModule.REPARTITION_DE_SERVICE],
  ["caissescashpad", MyCawanModule.CAISSES_CASHPAD],
  ["caissescsi", MyCawanModule.CAISSES_CSI],
  ["caissesetc", MyCawanModule.CAISSES_ETC],
  ["caissesinnovorder", MyCawanModule.CAISSES_INNOVORDER],
  ["caissesladdition", MyCawanModule.CAISSES_LADDITION],
  ["caissesLightSpeed", MyCawanModule.CAISSES_LIGHTSPEED],
  ["caissesmedialog", MyCawanModule.CAISSES_MEDIALOG],
  ["caissespi", MyCawanModule.CAISSES_PI],
  ["caissespointex", MyCawanModule.CAISSES_POINTEX],
  ["caissestiller", MyCawanModule.CAISSES_TILLER],
  ["caissesZelty", MyCawanModule.CAISSES_ZELTY],
  ["caisseszeshop", MyCawanModule.CAISSES_ZESHOP],
  ["mycawanstaff", MyCawanModule.MYCAWANSTAFF],
  ["pointeuse", MyCawanModule.POINTEUSE],
  ["haccp", MyCawanModule.HACCP],
  [
    "sondesdisruptivetechnologies",
    MyCawanModule.SONDES_DISRUPTIVE_TECHNOLOGIES,
  ],
  ["com.netresto.rh.saas.core.rh", MyCawanModule.RH_GESTION_TEMPS],
  ["electronicsignatureyousign", MyCawanModule.SIGNATURE_ELECTRONIQUE],
  ["cegedim", MyCawanModule.EXPORT_PAIE_CEGEDIM],
  ["pleiades", MyCawanModule.EXPORT_PAIE_PLEIADES],
  ["com.netresto.export.sage.exportsage", MyCawanModule.EXPORT_PAIE_SAGE],
  ["silae", MyCawanModule.EXPORT_PAIE_SILAE],
  ["sage", MyCawanModule.SYNCHRO_SAGE],
  ["cegid", MyCawanModule.CEGID],
  ["ctrlsite", MyCawanModule.AUDITS],
  ["com.netresto.intranet.intranet", MyCawanModule.DOCUMENTS],
  ["energie", MyCawanModule.ENERGIE],
  ["fileexchange", MyCawanModule.IMPORT_EXPORT],
]);
