import type { MyCawanModule } from "@/shared/model/modules/MyCawanModule";
import { ConventionCollective } from "../SitePreferences";
import type { Site } from "../UserDomains";
import { CheckMode, type SiteAccessChecker } from "./AccessCheckersApi";

export class ModuleSubscriptionChecker implements SiteAccessChecker {
  constructor(
    private readonly checkMode: CheckMode,
    private readonly modules: MyCawanModule[],
  ) {}

  public checkSite(site: Site): boolean {
    switch (this.checkMode) {
      case CheckMode.ANY:
        return this.modules.some((m) => site.hasModule(m));
      case CheckMode.ALL:
        return this.modules.every((m) => site.hasModule(m));
    }
  }
}

export class CycleTravailEnabled implements SiteAccessChecker {
  public checkSite(site: Site): boolean {
    return site.preferences.cyclesTravail().length > 0;
  }
}

export class RcrEnabled implements SiteAccessChecker {
  public checkSite(site: Site): boolean {
    return (
      site.preferences.conventionCollective() === ConventionCollective.HCR &&
      site.preferences.isRCREnabled()
    );
  }
}
