/******************************
 * GENERATED FILE, DO NOT EDIT *
 *******************************/ import type {
  ReactNode,
  SVGProps,
} from "react";
export default function IconHandshake(
  props: SVGProps<SVGSVGElement>
): ReactNode {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16px"
      height="16px"
      viewBox="0 0 16 16"
      color="currentColor"
      {...props}
    >
      <path
        d="M1.428 9.151q.274 0 .383-.219.11-.219 0-.438-.11-.22-.383-.22-.274 0-.384.22-.11.219 0 .438.11.22.384.22m10.084-.397-.264-.342-.284-.37-.26-.335q-.17-.22-.29-.363-.12-.144-.278-.322-.157-.178-.308-.335l-.856.958q-.568.644-1.427.63t-1.407-.671q-.39-.472-.387-1.082t.4-1.075l1.212-1.41q-.15-.075-.349-.113t-.325-.041-.387.003-.335.007q-.63 0-1.082.452L3.803 5.427h-1.06V9.15l.143-.003q.11-.004.15 0 .042.003.134.013t.14.031.12.058q.072.038.127.093l2.033 1.999q.787.76 1.554.76.534 0 .856-.322.39.137.77-.055.38-.191.497-.582.506.041.869-.3.137-.124.246-.312.11-.189.096-.346.069.068.295.068.294 0 .527-.143.233-.144.339-.363.106-.22.082-.49t-.209-.503m1.089.397h.657V5.646h-.637l-1.075-1.232q-.452-.52-1.157-.52H9.246q-.61 0-1 .458l-1.43 1.664q-.192.226-.192.513t.185.514q.294.349.753.356.459.006.76-.336l1.321-1.492q.171-.158.366-.148t.322.185.058.387q.11.13.384.431.274.302.41.466.199.246.565.722.367.476.442.579.356.452.41.958m1.971 0q.274 0 .384-.219t0-.438-.384-.22-.383.22 0 .438.383.22m1.315-3.943v4.381q0 .178-.13.308t-.308.13h-2.972q-.184.445-.561.73-.377.284-.856.352-.226.329-.55.558-.326.23-.703.312-.287.362-.715.558-.428.195-.88.167-.41.233-.862.27t-.873-.095-.801-.366-.709-.555l-1.965-1.93H.552q-.179 0-.309-.13t-.13-.309v-4.6q0-.178.13-.308t.308-.13h2.883q.095-.096.321-.33.226-.232.326-.328.099-.096.3-.274.203-.178.347-.256t.349-.175q.205-.096.424-.133.22-.038.466-.038h.8q.678 0 1.24.383.561-.383 1.239-.383h1.143q.24 0 .459.041t.387.1.352.18.305.213.294.27.267.288.281.329.284.332h2.43q.179 0 .309.13t.13.308"
        fill="currentColor"
      />
    </svg>
  );
}
