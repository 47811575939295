export class GenericPreferences {
  constructor(public readonly preferences: Map<string, string>) {}

  getString(key: string): string | undefined {
    return this.preferences.get(key);
  }

  parseBoolean(key: string): boolean | undefined {
    return this.preferences.get(key) === "1";
  }

  parseNumber(key: string): number | undefined {
    const str = this.preferences.get(key);
    return str === undefined ? undefined : Number(this.preferences.get(key));
  }

  parseJson<T>(key: string, translator: (val: unknown) => T): T | undefined {
    const str = this.preferences.get(key);
    return str === undefined ? undefined : translator(JSON.parse(str));
  }
}
