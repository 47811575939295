import type { UserPermissions } from "@/shared/model/user/UserPermissions";
import type { UserPreferences } from "@/shared/model/user/UserPreferences";

export class AppUserInfo {
  constructor(
    readonly loggedIn: boolean,
    readonly userInfo?: UserInfo,
    readonly permissions?: UserPermissions,
    readonly preferences?: UserPreferences,
  ) {}
}

export class UserInfo {
  constructor(
    public readonly userId: string,
    public readonly username: string,
    public readonly email: UserEmail,
    public readonly firstName: string,
    public readonly lastName: string,
    public readonly language: string,
  ) {}

  fullName(): string {
    return `${this.firstName} ${this.lastName}`;
  }
}

export class UserEmail {
  constructor(
    public readonly current: string,
    public readonly next: string | null,
    public readonly verified: boolean,
  ) {}
}
