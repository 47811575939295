import type { AppUserInfo } from "@/shared/model/user/AppUserInfo";
import type { Page } from "./ApplicationPages";
import { buildAllPages } from "./JsonPages";
import jsonPagesData from "./pages.json";

export const ALL_PAGES = buildAllPages(jsonPagesData);

export function buildPagesMapFor(aui: AppUserInfo): Map<string, Page> {
  const res = new Map<string, Page>();
  ALL_PAGES.forEach((page) => {
    if (page.userCanAccess(aui)) res.set(page.key, page);
  });
  return res;
}

export function getPage(pageKey: string): Page {
  const res = ALL_PAGES.get(pageKey);
  if (res === undefined) {
    throw Error(`Missing page key: '${pageKey}'`);
  }
  return res;
}

export function getPageByUrl(url: string): Page | undefined {
  let res: Page | undefined;
  for (const page of ALL_PAGES.values()) {
    // TODO: first check if react, then use react-dom-router matching ?
    if (url.startsWith(page.buildUrl())) {
      res = page;
      break;
    }
  }
  return res;
}
