/******************************
 * GENERATED FILE, DO NOT EDIT *
 *******************************/ import type {
  ReactNode,
  SVGProps,
} from "react";
export default function IconSliders(props: SVGProps<SVGSVGElement>): ReactNode {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16px"
      height="16px"
      viewBox="0 0 16 16"
      color="currentColor"
      {...props}
    >
      <path
        d="M3.667 12.667V14H0v-1.333zm3.666-1.334q.271 0 .47.198Q8 11.73 8 12v2.667q0 .27-.198.468t-.469.198H4.667q-.271 0-.47-.198Q4 14.938 4 14.667V12q0-.27.198-.469t.469-.198Zm1.667-4v1.334H0V7.333ZM2.333 2v1.333H0V2ZM16 12.667V14H8.333v-1.333Zm-10-12q.27 0 .469.198.198.197.198.468V4q0 .27-.198.469T6 4.667H3.333q-.27 0-.468-.198T2.667 4V1.333q0-.27.198-.468t.468-.198ZM12.667 6q.27 0 .468.198t.198.469v2.666q0 .271-.198.47-.197.197-.468.197H10q-.27 0-.469-.198t-.198-.469V6.667q0-.271.198-.47Q9.73 6 10 6ZM16 7.333v1.334h-2.333V7.333ZM16 2v1.333H7V2Z"
        fill="currentColor"
      />
    </svg>
  );
}
