import type { RouteObject } from "react-router-dom";
import PageForbidden from "./pages/forbidden/PageForbidden";
import PageNotFound from "./pages/not-found/PageNotFound";
import { homeRoutes } from "@/pages/home/routes";
import { sandboxRoutes } from "@/pages/sandbox/routes";

export const routes: RouteObject[] = [
  ...homeRoutes,
  ...sandboxRoutes,
  // Default pages -----------------------------------------------------------
  {
    path: "/forbidden",
    element: <PageForbidden />,
  },
  {
    path: "*",
    element: <PageNotFound />,
  },
];
