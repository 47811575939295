import { MainHeaderMenuButton } from "@/layout/header/menu/components/button/MainHeaderMenuButton";
import type { ReactNode } from "react";
import { useTranslation } from "react-i18next";
import { MainHeaderDropDown } from "@/layout/header/menu/components/drop-down/MainHeaderDropDown";
import type { Domain } from "@/shared/model/user/UserDomains";
import { MainHeaderDropDownItemDomain } from "@/layout/header/menu/domains/DomainDropDownItem";
import IconHouses from "@/shared/components/generic/icons/IconHouses.tsx";

export interface Properties {
  menuKey: string;
  activeMenuKey?: string;
  onClick: (menuKey: string) => void;
  domainsWhereAdmin?: Domain[];
}

export default function DomainsManagementMenu(props: Properties): ReactNode {
  const { t } = useTranslation(["menus"]);

  if (props.domainsWhereAdmin === undefined) {
    return undefined;
  }
  if (props.domainsWhereAdmin.length === 0) {
    return undefined;
  }

  return (
    <MainHeaderMenuButton
      icon={IconHouses}
      tooltipText={t("menus:domainsManagement.title")}
      onClick={() => {
        props.onClick(props.menuKey);
      }}
      active={props.menuKey === props.activeMenuKey}
    >
      <MainHeaderDropDown title={t("menus:domainsManagement.title")}>
        {props.domainsWhereAdmin.map((domain) => (
          <MainHeaderDropDownItemDomain key={domain.id} domain={domain} />
        ))}
      </MainHeaderDropDown>
    </MainHeaderMenuButton>
  );
}
