import usePageNavigation from "@/shared/model/pages-management/usePageNavigation";
import classNames from "classnames";
import type { MouseEvent, ReactNode } from "react";
import { useTranslation } from "react-i18next";
import "./MainMenu.scss";
import type MainMenuBreadCrumb from "./model/MainMenuBreadCrumb";
import MainMenuItem from "./MainMenuItem";
import type Menu from "./model/Menu";
import SubMenu from "./SubMenu";
import { subMenuColors } from "./styling-helpers/SubMenuColors";

export interface Properties {
  menuItem: Menu;
  userCrumb: MainMenuBreadCrumb;
  onUserChangedCrumb: (bc: MainMenuBreadCrumb) => void;
}

export default function MainMenuLevel2(props: Properties): ReactNode {
  const { t } = useTranslation(["mainMenu"]);
  const pageNav = usePageNavigation();
  function handleClick(evt: MouseEvent): void {
    evt.stopPropagation();
    if (props.userCrumb.isLastActive(props.menuItem)) {
      pageNav.jumpTo(props.menuItem.getTargetPage());
    } else {
      props.onUserChangedCrumb(
        props.userCrumb
          .selectMenu(0, props.menuItem.getParent())
          .selectMenu(1, props.menuItem),
      );
    }
  }

  function isActive(): boolean {
    return props.userCrumb.isOnPath(1, props.menuItem);
  }

  return (
    <div
      className={classNames("main-menu-level-2", isActive() && "active")}
      onClick={(e) => {
        handleClick(e);
      }}
      style={
        isActive() ?
          { backgroundColor: subMenuColors(props.menuItem).bg }
        : undefined
      }
    >
      {isActive() && (
        <SubMenu
          depth={2}
          subLevel={1}
          parentMenu={props.menuItem}
          userCrumb={props.userCrumb}
          onUserChangedCrumb={props.onUserChangedCrumb}
        >
          {props.menuItem.children.map((m) => (
            <MainMenuItem
              key={m.key}
              depth={2}
              menuItem={m}
              userCrumb={props.userCrumb}
              onUserChangedCrumb={props.onUserChangedCrumb}
            />
          ))}
        </SubMenu>
      )}
      <div className="label">{t(`mainMenu:${props.menuItem.key}`)}</div>
      <div className="arrow-right">
        <div />
      </div>
    </div>
  );
}
