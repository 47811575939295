import type { Page } from "@/shared/model/pages-management/ApplicationPages";
import type { AppUserInfo } from "@/shared/model/user/AppUserInfo";
import Menu from "./Menu";

export class MainMenuFilter {
  constructor(private readonly allPages: Map<string, Page>) {}

  filteredForUser(aui: AppUserInfo, menu: Menu): Menu | undefined {
    if (this.userCanSeeMenu(aui, menu)) {
      return new Menu(
        menu.key,
        menu.children
          .map((m) => this.filteredForUser(aui, m))
          .filter((m) => m !== undefined),
        menu.targetPage,
      );
    }
  }

  private userCanSeeMenu(aui: AppUserInfo, menu: Menu): boolean {
    if (!aui.loggedIn) {
      return false;
    }
    if (menu.key === "root") {
      return true;
    }
    if (
      menu.targetPage !== undefined &&
      this.userCanAccessPage(aui, menu.targetPage)
    ) {
      return true;
    }
    return menu.children.some((m) => this.userCanSeeMenu(aui, m));
  }

  private userCanAccessPage(aui: AppUserInfo, pageKey: string): boolean {
    const page = this.allPages.get(pageKey);
    if (page === undefined) {
      throw new Error(`Missing page key: '${pageKey}'`);
    }
    return page.userCanAccess(aui);
  }
}
