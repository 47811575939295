import type { MouseEvent, ReactNode } from "react";
import { useEffect, useLayoutEffect, useRef } from "react";
import type MainMenuBreadCrumb from "./model/MainMenuBreadCrumb";
import type Menu from "./model/Menu";
import "./MenuItems.scss";
import classNames from "classnames";
import { subMenuColors } from "./styling-helpers/SubMenuColors";

export interface Properties {
  depth: number; // actual depth in menu tree (main_menu.json)
  subLevel: 1 | 2; // column number for icons column
  parentMenu: Menu;
  userCrumb: MainMenuBreadCrumb;
  onUserChangedCrumb: (bc: MainMenuBreadCrumb) => void;
  children: ReactNode;
}

export default function SubMenu(props: Properties): ReactNode {
  const containerRef = useRef<HTMLDivElement>(null);
  useLayoutEffect(() => {
    if (containerRef.current?.parentElement != null) {
      const mainMenuTop = 65;
      const container = containerRef.current;
      const parent = container.parentElement;
      if (parent != null) {
        const containerRect = container.getBoundingClientRect();
        if (containerRect.top < mainMenuTop) {
          container.style.top = "0px";
        }
      }
    }
  });
  useEffect(() => {
    if (containerRef.current?.parentElement != null) {
      containerRef.current.classList.add("appear");
    }
  });

  if (!props.parentMenu.hasChildren()) {
    return undefined;
  }
  const classes = classNames(
    "sub-level",
    ["", "sub-level-1", "sub-level-2"][props.subLevel],
  );

  const { bg } = subMenuColors(props.parentMenu);
  return (
    <div ref={containerRef} className={classes} style={{ backgroundColor: bg }}>
      <div
        onClick={(e) => {
          e.stopPropagation();
        }}
        className="close-row"
        onMouseOver={(e) => {
          e.stopPropagation();
          props.onUserChangedCrumb(
            props.userCrumb.selectMenu(props.depth - 1, props.parentMenu),
          );
        }}
      >
        <div
          onClick={(e) => {
            closeSubMenu(e);
          }}
          className="icon"
        />
      </div>
      {props.children}
    </div>
  );

  function closeSubMenu(evt: MouseEvent): void {
    evt.stopPropagation();
    props.onUserChangedCrumb(props.userCrumb.pop());
  }
}
