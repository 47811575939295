import { range } from "./Arrays";
import { indexesOf } from "./Strings";

export interface Fragment {
  start: number;
  end: number;
  matched: boolean;
}

export function splitMatching(haystack: string, needles: string[]): Fragment[] {
  const charsMatched = range(haystack.length).map(() => false);
  for (const keyword of needles) {
    for (const foundAt of indexesOf(haystack, keyword)) {
      charsMatched.fill(true, foundAt, foundAt + keyword.length);
    }
  }
  const changes = [0, ...changesAt(charsMatched), haystack.length];
  const res = [];
  for (let i = 0; i < changes.length - 1; ++i) {
    const start = changes[i];
    const end = changes[i + 1];
    const matched = charsMatched[start];
    res.push({ start, end, matched });
  }
  return res;
}

function changesAt(matched: boolean[]): number[] {
  if (matched.length === 0) {
    return [];
  }
  const flipsAt = [];
  for (let i = 1; i < matched.length; ++i) {
    if (matched[i] != matched[i - 1]) {
      flipsAt.push(i);
    }
  }
  return flipsAt;
}

export const _forUnitTests = {
  changesAt,
};
