import { MainHeaderDropDown } from "@/layout/header/menu/components/drop-down/MainHeaderDropDown";
import { MainHeaderDropDownItem } from "@/layout/header/menu/components/drop-down/MainHeaderDropDownItem";
import { MainHeaderMenuButton } from "@/layout/header/menu/components/button/MainHeaderMenuButton";
import { bookmarksQuery } from "@/layout/header/menu/bookmarks/services/BookmarksApi";
import { useQuery } from "@tanstack/react-query";
import { type ReactNode, useContext } from "react";
import { useTranslation } from "react-i18next";
import type { Bookmark } from "@/layout/header/menu/bookmarks/services/dtos/Bookmark";
import { getPageByUrl } from "@/shared/model/pages-management/AllPages";
import { AppUserInfoContext } from "@/shared/model/user/AppUserInfoContext";
import IconWorldPointer from "@/shared/components/generic/icons/IconWorldPointer.tsx";

export interface Properties {
  menuKey: string;
  activeMenuKey?: string;
  onClick: (menuKey: string) => void;
}

export default function BookmarksMenu(
  props: Properties,
): ReactNode | undefined {
  const { t } = useTranslation(["menus"]);
  const { data: bookmarks } = useQuery(bookmarksQuery());
  const appUserInfo = useContext(AppUserInfoContext);

  function allowedBookmark(bookmark: Bookmark): boolean {
    return (
      isExternalBookmark(bookmark) ||
      (getPageByUrl(bookmark.link)?.userCanAccess(appUserInfo) ?? true)
    );
  }

  function isExternalBookmark(bookmark: Bookmark): boolean {
    return !(
      bookmark.link.startsWith("content/") ||
      bookmark.link.startsWith("/content/")
    );
  }

  const _bookmarks = (bookmarks ?? []).filter(allowedBookmark);

  if (_bookmarks.length === 0) {
    return undefined;
  }

  return (
    <MainHeaderMenuButton
      icon={IconWorldPointer}
      tooltipText={t("menus:bookmarks.title")}
      onClick={() => {
        props.onClick(props.menuKey);
      }}
      active={props.menuKey === props.activeMenuKey}
    >
      <MainHeaderDropDown title={t("menus:bookmarks.title")}>
        {_bookmarks.map((bm) => {
          return (
            <MainHeaderDropDownItem
              key={bm.label}
              address={bm.link}
              label={bm.label}
              target={bm.target}
            />
          );
        })}
      </MainHeaderDropDown>
    </MainHeaderMenuButton>
  );
}
