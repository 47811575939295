/* eslint-disable react-refresh/only-export-components */
import { lazy } from "react";

import type { RouteObject } from "react-router-dom";

const HomePage = lazy(() => import("./HomePage"));
const DomainDirectory = lazy(
  () => import("./directory/domain/DomainDirectory"),
);
const SiteView = lazy(
  () => import("./directory/domain/site/site-view/SiteView"),
);
const DomainDirectoryPage = lazy(
  () => import("./directory/DomainDirectoryPage"),
);

export const homeRoutes: RouteObject[] = [
  // Annuaire sites ------------------------------------------------------------
  {
    path: "/home",
    element: <HomePage />,
    children: [
      {
        path: "/home/directory",
        element: <DomainDirectoryPage />,
        children: [
          {
            path: "domain/:domainId",
            element: <DomainDirectory />,
            children: [
              {
                path: "site/:siteId",
                element: <SiteView />,
              },
            ],
          },
        ],
      },
    ],
  },
];
