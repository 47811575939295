import type { ReactNode } from "react";
import { forwardRef } from "react";
import "./SupportInfo.scss";
import { Tooltip } from "@mui/material";
import { SupportTooltip } from "@/layout/sticky-column/support-info/SupportTooltip.tsx";
import IconQuestionMarkBubble from "@/shared/components/generic/icons/IconQuestionMarkBubble.tsx";

export default function SupportInfo(): ReactNode {
  const supportBlue = "#4989e9";
  const Wrapper = forwardRef<HTMLDivElement>(function TooltipRef(props, ref) {
    //  Spread the props to the underlying DOM element.
    return (
      <div {...props} ref={ref} className="support-info MuiTooltip-root">
        <IconQuestionMarkBubble
          width="30px"
          height="30px"
          color={supportBlue}
        />
      </div>
    );
  });

  return (
    <Tooltip
      title={<SupportTooltip />}
      arrow
      placement="top-end"
      className="support-info"
      slotProps={{
        tooltip: { sx: { backgroundColor: supportBlue } },
        arrow: { sx: { color: supportBlue } },
      }}
    >
      <Wrapper />
    </Tooltip>
  );
}
