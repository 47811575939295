import { isDigitsOnly } from "@/shared/utils/Strings";

export default class InputConfig {
  constructor(
    public readonly numericInput: boolean,
    public readonly inputMinLength: number,
  ) {}

  validInput(input: string): boolean {
    return this.numericInput ?
        isDigitsOnly(input)
      : input.length >= this.inputMinLength;
  }
}
