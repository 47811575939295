/* eslint-disable react-refresh/only-export-components */
import { lazy } from "react";
import type { RouteObject } from "react-router-dom";

const SandBox = lazy(() => import("@/pages/sandbox/Sandbox"));
export const sandboxRoutes: RouteObject[] =
  import.meta.env.DEV ?
    [
      {
        path: "/sandbox",
        element: <SandBox />,
      },
    ]
  : [];
