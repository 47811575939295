import { DojoPage, ReactPage, type Page } from "./ApplicationPages";
import {
  type SpecialBasedOnUser,
  buildCheckerBasedOnUser,
  type SpecialBasedOnSite,
  buildCheckerBasedOnSite,
} from "./JsonPagesSpecials";
import { mapModule } from "@/shared/model/user/UserJsCache";
import {
  ignoreSpecialKeys as ignoredSpecialKeys,
  specialKeysBasedOnSite,
  specialKeysBasedOnUser,
  unknownSpecialKey,
} from "./JsonPagesSpecials";
import {
  CheckMode,
  CompositeSiteAccessChecker,
  CompositeUserAccessChecker,
  DomainSitesAccessChecker,
  PageAccessChecker,
  type UserAccessChecker,
  type DomainAccessChecker,
  type SiteAccessChecker,
  CompositeDomainAccessChecker,
} from "@/shared/model/user/permissions/AccessCheckersApi";
import { ModuleSubscriptionChecker } from "@/shared/model/user/permissions/SiteAccessCheckers";
import { SecurityKeyChecker } from "@/shared/model/user/permissions/DomainAccessCheckers";
import { UserLoggedInChecker } from "@/shared/model/user/permissions/UserAccessCheckers";
import type { MyCawanModule } from "@/shared/model/modules/MyCawanModule";

// -----------------------------------------------------------------------------
// Interfaces for pages.json data
// These interfaces must not be used outside of this module.
// (They are exported for unit testing purpose only)
// -----------------------------------------------------------------------------

export interface JsonPage {
  key: string;
  framework: DojoJson | ReactJson;
  permissions?: { read: PermissionsJson };
}

interface DojoJson {
  name: "dojo";
  details: {
    plugin: string;
    cType: string;
    task: string;
    query_params?: string;
  };
}

interface ReactJson {
  name: "react";
  route: string;
}

export type PermissionsJson = {
  without_subscription?: RightsKeys;
  subscriptions?: Subscriptions;
  pages?: Pages;
  specials?: Specials;
} & JsCheckMode;

interface JsCheckMode {
  checkMode?: "any" | "all";
}

type RightsKeys = { rights_keys: string[] } & JsCheckMode;

type Subscriptions = { list: Subscription[] } & JsCheckMode;
type Subscription = RightsKeys & JsCheckMode & SubscriptionKey;
interface SubscriptionKey {
  subscription_key?: string;
  subscription_keys?: string[];
}

type Pages = { pages_keys: string[] } & JsCheckMode;

type Specials = { specials_keys: string[] } & JsCheckMode;

// -----------------------------------------------------------------------------
// Building / converting functions
// -----------------------------------------------------------------------------

export function buildAllPages(jsonData: unknown): Map<string, Page> {
  const jsonPages = jsonData as JsonPage[];
  checkUniquePagesKeys(jsonPages);
  // First, build a map of pages with "direct" permission checkers only.
  // (i.e. without checks for children pages):
  const res = new Map<string, Page>(
    jsonPages.map(buildPageWithoutChildren).map((page) => [page.key, page]),
  );
  // Then for each page, add permission checkers for children pages:
  jsonPages.forEach((jsonPage) => {
    const subPages = jsonPage.permissions?.read.pages;
    if (subPages === undefined) {
      return;
    }
    const childrenPages: Page[] = [];
    for (const childKey of subPages.pages_keys) {
      const childPage = res.get(childKey);
      if (childPage === undefined) {
        throw new Error(
          `Page not found for key: '${childKey}' (child of page: '${jsonPage.key}')`,
        );
      }
      childrenPages.push(childPage);
    }
    const parentPage = res.get(jsonPage.key);
    childrenPages.forEach((childPage) => parentPage?.addChildPage(childPage));
  });
  return res;
}

function checkUniquePagesKeys(jsonPages: JsonPage[]): void {
  const keys = new Set();
  for (const jsonPage of jsonPages) {
    if (keys.has(jsonPage.key)) {
      throw new Error(`Duplicate key in pages.json: '${jsonPage.key}'`);
    }
    keys.add(jsonPage.key);
  }
}

function buildPageWithoutChildren(jsonPage: JsonPage): Page {
  const pageChecker = buildCheckersWithoutChildren(jsonPage.permissions?.read);
  if (jsonPage.framework.name === "react") {
    return new ReactPage(jsonPage.key, jsonPage.framework.route, pageChecker);
  } else {
    return new DojoPage(
      jsonPage.key,
      jsonPage.framework.details.plugin,
      jsonPage.framework.details.cType,
      jsonPage.framework.details.task,
      pageChecker,
      jsonPage.framework.details.query_params,
    );
  }
}

function buildCheckersWithoutChildren(
  jsonPerms: PermissionsJson | undefined,
): PageAccessChecker {
  if (jsonPerms === undefined) {
    // Case of an implicitly public page
    return new PageAccessChecker(undefined, undefined);
  }

  // TODO: always add UserLoggedInChecker and VerifiedEmailChecker ?

  // Build domain checkers:
  const domainCheckers: DomainAccessChecker[] = [];
  if (jsonPerms.without_subscription !== undefined) {
    domainCheckers.push(
      buildPermissionsChecker(jsonPerms.without_subscription),
    );
  }

  if (jsonPerms.subscriptions !== undefined) {
    domainCheckers.push(buildWithSubscriptionsChecker(jsonPerms.subscriptions));
  }

  // Build special checkers (user and site based)
  const userBasedSpecials: UserAccessChecker[] = [];
  const siteBasedSpecials: SiteAccessChecker[] = [];

  if (jsonPerms.specials !== undefined) {
    userBasedSpecials.push(...buildUserBasedSpecials(jsonPerms.specials));
    siteBasedSpecials.push(...buildSiteBasedSpecials(jsonPerms.specials));
  }

  // Add site base domain checkers list:
  if (siteBasedSpecials.length > 0) {
    domainCheckers.push(
      new DomainSitesAccessChecker(
        CheckMode.ANY, // (any site)
        new CompositeSiteAccessChecker(
          mapCheckMode(jsonPerms.specials),
          siteBasedSpecials,
        ),
      ),
    );
  }

  // Create domains checker and user checker:
  const domainChecker =
    domainCheckers.length === 0 ?
      undefined
    : new CompositeDomainAccessChecker(mapCheckMode(jsonPerms), domainCheckers);
  const userChecker =
    userBasedSpecials.length === 0 ?
      undefined
    : new CompositeUserAccessChecker(CheckMode.ANY, userBasedSpecials);

  if (userChecker === undefined && domainChecker !== undefined) {
    // If domain checker but no user check => force user logged in check
    return new PageAccessChecker(new UserLoggedInChecker(), domainChecker);
  }
  return new PageAccessChecker(userChecker, domainChecker);
}

function buildUserBasedSpecials(specials: Specials): UserAccessChecker[] {
  return specials.specials_keys
    .filter((k) => isValidSpecialKey(k))
    .map((k) => mapUserBasedSpecialKey(k))
    .filter((userSpecial) => userSpecial !== undefined)
    .map((userSpecial) => buildCheckerBasedOnUser(userSpecial));
}

function buildSiteBasedSpecials(specials: Specials): SiteAccessChecker[] {
  return specials.specials_keys
    .filter((k) => isValidSpecialKey(k))
    .map((k) => mapSiteBasedSpecialKey(k))
    .filter((siteSpecial) => siteSpecial !== undefined)
    .map((siteSpecial) => buildCheckerBasedOnSite(siteSpecial));
}

function buildPermissionsChecker(rights: RightsKeys): SecurityKeyChecker {
  return new SecurityKeyChecker(mapCheckMode(rights), rights.rights_keys);
}

function buildWithSubscriptionsChecker(
  subscriptions: Subscriptions,
): DomainAccessChecker {
  const modulesOf = (sub: Subscription): MyCawanModule[] => {
    const modulesKeys: string[] = sub.subscription_keys ?? [];
    if (sub.subscription_key !== undefined) {
      modulesKeys.push(sub.subscription_key);
    }
    return modulesKeys.map(mapModule);
  };

  return new CompositeDomainAccessChecker(
    mapCheckMode(subscriptions),
    subscriptions.list.map(
      (sub) =>
        new CompositeDomainAccessChecker(CheckMode.ALL, [
          new DomainSitesAccessChecker(
            CheckMode.ANY,
            new ModuleSubscriptionChecker(CheckMode.ALL, modulesOf(sub)),
          ),
          buildPermissionsChecker(sub),
        ]),
    ),
  );
}

// -----------------------------------------------------------------------------
// Mappings
// -----------------------------------------------------------------------------

// CheckMode mapping

function mapCheckMode(jsMode: JsCheckMode | undefined): CheckMode {
  switch (jsMode?.checkMode) {
    case "all":
      return CheckMode.ALL;
    case "any":
    case undefined:
      return CheckMode.ANY;
  }
}

// Special keys mappings

function mapUserBasedSpecialKey(key: string): SpecialBasedOnUser | undefined {
  return specialKeysBasedOnUser.get(key);
}

function mapSiteBasedSpecialKey(key: string): SpecialBasedOnSite | undefined {
  return specialKeysBasedOnSite.get(key);
}

function isValidSpecialKey(key: string): boolean {
  if (ignoredSpecialKeys.includes(key)) {
    return false;
  }
  if (unknownSpecialKey(key)) {
    throw new Error(`Unknown special key: ${key}`);
  }
  return true;
}

export const _forUnitTests = {
  buildPageWithoutChildren,
};
