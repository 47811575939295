import type SearchConfig from "@/layout/header/searchBar/model/search/SearchConfig";
import type { AppUserInfo } from "@/shared/model/user/AppUserInfo";

export default class ModuleSearchConfig {
  constructor(
    public readonly i18nKey: string,
    public readonly searches: SearchConfig[],
  ) {
    searches.forEach((s) => (s.parent = this));
  }

  public userCanAccess(aui: AppUserInfo): boolean {
    return this.searches.some((s) => s.userCanAccess(aui));
  }
}
