import { MainHeaderDropDown } from "@/layout/header/menu/components/drop-down/MainHeaderDropDown";
import { MainHeaderDropDownItem } from "@/layout/header/menu/components/drop-down/MainHeaderDropDownItem";
import { MainHeaderMenuButton } from "@/layout/header/menu/components/button/MainHeaderMenuButton";
import type { UserInfo } from "@/shared/model/user/AppUserInfo";
import logout from "@/shared/model/user/Logout";
import type { ReactNode } from "react";
import { useTranslation } from "react-i18next";
import IconUserCircle from "@/shared/components/generic/icons/IconUserCircle.tsx";
import IconIdCard from "@/shared/components/generic/icons/IconIdCard.tsx";
import IconSliders from "@/shared/components/generic/icons/IconSliders.tsx";
import IconInvestigator from "@/shared/components/generic/icons/IconInvestigator.tsx";
import IconUserFill from "@/shared/components/generic/icons/IconUserFill.tsx";
import IconAtSign from "@/shared/components/generic/icons/IconAtSign.tsx";
import IconPadlock from "@/shared/components/generic/icons/IconPadlock.tsx";
import IconArrowExitRight from "@/shared/components/generic/icons/IconArrowExitRight.tsx";

export interface Properties {
  menuKey: string;
  activeMenuKey?: string;
  onClick: (menuKey: string) => void;
  userInfo?: UserInfo;
}

export default function UserAccountMenu(props: Properties): ReactNode {
  const { t } = useTranslation(["menus"]);

  if (props.userInfo === undefined) {
    return undefined;
  }
  return (
    <MainHeaderMenuButton
      key="user"
      icon={IconUserCircle}
      tooltipText={t("menus:userAccount.title")}
      onClick={() => {
        props.onClick(props.menuKey);
      }}
      active={props.menuKey === props.activeMenuKey}
    >
      <MainHeaderDropDown title={props.userInfo.fullName()}>
        <MainHeaderDropDownItem
          key="information"
          label={t("menus:userAccount.information")}
          address="/content/currentuser/?task=viewinfo"
          icon={IconIdCard}
        />
        <MainHeaderDropDownItem
          key="preferences"
          label={t("menus:userAccount.preferences")}
          address="/content/currentuser/?task=viewprefs"
          icon={IconSliders}
        />
        <MainHeaderDropDownItem
          key="password"
          label={t("menus:userAccount.password")}
          address="/content/currentuser/?task=viewchangepassword"
          icon={IconInvestigator}
        />
        <MainHeaderDropDownItem
          key="username"
          label={t("menus:userAccount.username")}
          address="/content/currentuser/?task=viewchangelogin"
          icon={IconUserFill}
        />
        <MainHeaderDropDownItem
          key="email"
          label={t("menus:userAccount.email")}
          address="/content/currentuser/?task=viewchangemail"
          icon={IconAtSign}
        />
        <MainHeaderDropDownItem
          key="domainsAccess"
          label={t("menus:userAccount.domainsAccess")}
          address="/content/domain/?task=viewadd"
          icon={IconPadlock}
        />
        <MainHeaderDropDownItem
          key="logout"
          label={t("menus:userAccount.logout")}
          icon={IconArrowExitRight}
          onClick={() => {
            void logout();
          }}
        />
      </MainHeaderDropDown>
    </MainHeaderMenuButton>
  );
}
