import SearchConfig from "@/layout/header/searchBar/model/search/SearchConfig";
import ModuleSearchConfig from "@/layout/header/searchBar/model/search/ModuleSearchConfig";
import InputConfig from "@/layout/header/searchBar/model/search/input/InputConfig";
import ResultConfig from "@/layout/header/searchBar/model/search/result/ResultConfig";
import ColumnConfig from "@/layout/header/searchBar/model/search/result/ColumnConfig";

// -----------------------------------------------------------------------------
// Configurations des recherches HACCP
// -----------------------------------------------------------------------------

const HACCP_SEARCHES = new ModuleSearchConfig("haccp", [
  // Recherche livraisons
  new SearchConfig(
    "deliveries",
    "livraison",
    "HACCP_LIVRAISON_VISU",
    new InputConfig(false, 1),
    new ResultConfig([
      new ColumnConfig("domain"),
      new ColumnConfig("site"),
      new ColumnConfig("deliveryId"),
      new ColumnConfig("date"),
    ]),
  ),
  // Recherche mises sous vide
  new SearchConfig(
    "vacuumSeals",
    "sousvide",
    "HACCP_SOUSVIDE_VISU",
    new InputConfig(false, 5),
    new ResultConfig([
      new ColumnConfig("domain"),
      new ColumnConfig("site"),
      new ColumnConfig("batchNumber", true),
      new ColumnConfig("date"),
    ]),
  ),
  // Recherche traçabilité productions
  new SearchConfig(
    "productionsTraceability",
    "tracprod",
    "HACCP_TRACAPRODUCTION_VISU",
    new InputConfig(false, 5),
    new ResultConfig([
      new ColumnConfig("domain"),
      new ColumnConfig("site"),
      new ColumnConfig("batchNumber", true),
      new ColumnConfig("date"),
    ]),
  ),
  // Recherche tracabilité
  new SearchConfig(
    "traceability",
    "tracabilite",
    "HACCP_TRACABILITE_VISU",
    new InputConfig(false, 5),
    new ResultConfig([
      new ColumnConfig("domain"),
      new ColumnConfig("site"),
      new ColumnConfig("batchNumber", true),
      new ColumnConfig("date"),
      new ColumnConfig("product"),
    ]),
  ),
]);

export default HACCP_SEARCHES;
