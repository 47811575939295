import type { ReactNode } from "react";
import "./MainHeaderDropDownItem.scss";
import type { IconComponent } from "@/shared/components/generic/IconComponentType.ts";

export interface Properties {
  address?: string;
  target?: string;
  label: string;
  icon?: IconComponent;
  onClick?: (e: object) => void;
}

export function MainHeaderDropDownItem(props: Properties): ReactNode {
  return (
    <li className="main-header-drop-down-item">
      <a
        href={props.address}
        target={props.target ?? "_self"}
        onClick={props.onClick}
      >
        <span className="item">
          {props.icon?.({ width: "20px", height: "20px" })}
          <div>{props.label}</div>
        </span>
      </a>
    </li>
  );
}
