import buildMenuFor from "@/layout/sticky-column/main-menu/model/MainMenuBuilder";
import { AppUserInfoContext } from "@/shared/model/user/AppUserInfoContext";
import { useContext, useEffect, useRef, useState, type ReactNode } from "react";
import "./MainMenu.scss";
import MainMenuLevel1 from "./MainMenuLevel1";
import buildCurrentPageBreadCrumb from "./model/CurrentPageBreadCrumb";
import type MainMenuBreadCrumb from "./model/MainMenuBreadCrumb";

export function MainMenu(): ReactNode {
  const appUserInfo = useContext(AppUserInfoContext);
  const menu = buildMenuFor(appUserInfo);
  const pageCrumb = buildCurrentPageBreadCrumb(menu);
  const [userCrumb, setUserCrumb] = useState(pageCrumb);
  const leaveMenuDelayMs = 1000;

  const leaveTimerRef = useRef<ReturnType<typeof setTimeout> | undefined>(
    undefined,
  );
  const insideMenu = useRef(false);

  useEffect(() => {
    // Clear the interval when the component unmounts
    return (): void => {
      clearTimeout(leaveTimerRef.current);
    };
  }, []);

  if (menu === undefined) {
    return;
  }

  return (
    <div
      className="main-menu"
      onMouseEnter={enterMenu}
      onMouseLeave={leaveMenu}
    >
      <div>
        {menu.children.map((m) => (
          <MainMenuLevel1
            key={m.key}
            menuItem={m}
            userCrumb={userCrumb}
            onUserChangedCrumb={changeUserBreadCrumb}
          />
        ))}
      </div>
    </div>
  );

  function changeUserBreadCrumb(bc: MainMenuBreadCrumb): void {
    setUserCrumb(bc);
  }

  function enterMenu(): void {
    insideMenu.current = true;
  }

  function leaveMenu(): void {
    leaveTimerRef.current = setTimeout(resetMenu, leaveMenuDelayMs);
    insideMenu.current = false;
  }

  function resetMenu(): void {
    if (!insideMenu.current) {
      setUserCrumb(pageCrumb);
    }
  }
}
