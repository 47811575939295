import type { ReactNode } from "react";
import { MainMenu } from "./main-menu/MainMenu";
import SupportInfo from "./support-info/SupportInfo";
import "./StickyColumn.scss";

export default function StickyColumn(): ReactNode {
  return (
    <div className="sticky-column">
      <div>
        <MainMenu />
        <SupportInfo />
      </div>
    </div>
  );
}
