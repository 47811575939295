import type Menu from "@/layout/sticky-column/main-menu/model/Menu";
import MainMenuBreadCrumb from "./MainMenuBreadCrumb";

export function buildPageBreadCrumb(
  targetPageKey: string,
  menu: Menu | undefined,
): MainMenuBreadCrumb {
  let res = MainMenuBreadCrumb.create();
  if (menu !== undefined) {
    const steps = buildPathTo(targetPageKey, menu);
    for (let i = 0; i < steps.length; ++i) {
      res = res.selectMenu(i, steps[i]);
    }
  }
  return res;
}

function buildPathTo(pageKey: string, menu: Menu): Menu[] {
  const found = lookupTree(
    menu,
    (m) => m.hasTargetPage() && m.getTargetPage() === pageKey,
  );
  if (found !== undefined) {
    return found.pathFromRoot().slice(1);
  }
  return [];
}

function lookupTree(
  menu: Menu,
  matcher: (menu: Menu) => boolean,
): Menu | undefined {
  if (matcher(menu)) return menu;
  for (const child of menu.children) {
    const res = lookupTree(child, matcher);
    if (res !== undefined) {
      return res;
    }
  }
}
