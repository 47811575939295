import { getPageByUrl } from "@/shared/model/pages-management/AllPages";
import MainMenuBreadCrumb from "./MainMenuBreadCrumb";
import { buildPageBreadCrumb } from "./BreadCrumbBuilder";
import type Menu from "@/layout/sticky-column/main-menu/model/Menu";

export default function buildCurrentPageBreadCrumb(
  menu: Menu | undefined,
): MainMenuBreadCrumb {
  const currentPath = window.location.pathname;
  const targetPageKey = getPageByUrl(currentPath)?.key;
  if (targetPageKey === undefined) {
    return MainMenuBreadCrumb.create();
  }
  return buildPageBreadCrumb(targetPageKey, menu);
}
