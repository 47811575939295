import StickyColumn from "@/layout/sticky-column/StickyColumn";
import { MainHeader } from "@/layout/header/MainHeader";
import type { ReactNode } from "react";
import "./MainLayout.scss";

export function MainLayout({ children }: { children: ReactNode }): ReactNode {
  return (
    <div className="main-layout">
      <MainHeader />
      <StickyColumn />
      <div className="content">{children}</div>
    </div>
  );
}
