import type {
  SiteAccessChecker,
  UserAccessChecker,
} from "@/shared/model/user/permissions/AccessCheckersApi";
import {
  CycleTravailEnabled,
  RcrEnabled,
} from "@/shared/model/user/permissions/SiteAccessCheckers";
import {
  AnyDomainAdminChecker,
  UserFlagsChecker,
  UserLoggedInChecker,
  VerifiedEmailChecker,
} from "@/shared/model/user/permissions/UserAccessCheckers";
import { UserFlags } from "@/shared/model/user/UserPermissions";

// -------------------------------------------------------------------------------------------------
// "specials" are split in two categories:
// - SpecialBasedOnUser for permissions based on user state
// - SpecialBasedOnSite for permissions based on site state
// -------------------------------------------------------------------------------------------------

export enum SpecialBasedOnUser {
  PRIVATE,
  VERIFIED_EMAIL,
  ANY_DOMAIN_ADMIN,
  DEVELOPPER,
  SUPPORT,
  SALES_PERSON,
  BETA_TESTER,
}

export const specialKeysBasedOnUser = new Map([
  ["private", SpecialBasedOnUser.PRIVATE],
  ["mail_verified", SpecialBasedOnUser.VERIFIED_EMAIL],
  ["any_domain_admin", SpecialBasedOnUser.ANY_DOMAIN_ADMIN],
  ["developer", SpecialBasedOnUser.DEVELOPPER],
  ["support", SpecialBasedOnUser.SUPPORT],
  ["commercial_or_support", SpecialBasedOnUser.SALES_PERSON],
  ["beta_tester", SpecialBasedOnUser.BETA_TESTER],
]);

export function buildCheckerBasedOnUser(
  special: SpecialBasedOnUser,
): UserAccessChecker {
  switch (special) {
    case SpecialBasedOnUser.PRIVATE:
      return new UserLoggedInChecker();
    case SpecialBasedOnUser.VERIFIED_EMAIL:
      return new VerifiedEmailChecker();
    case SpecialBasedOnUser.ANY_DOMAIN_ADMIN:
      return new AnyDomainAdminChecker();
    case SpecialBasedOnUser.DEVELOPPER:
      return new UserFlagsChecker(UserFlags.createDevelopper());
    case SpecialBasedOnUser.SUPPORT:
      return new UserFlagsChecker(UserFlags.createSupport());
    case SpecialBasedOnUser.SALES_PERSON:
      return new UserFlagsChecker(UserFlags.createSalesPerson());
    case SpecialBasedOnUser.BETA_TESTER:
      return new UserFlagsChecker(UserFlags.createDevelopper());
  }
}

// -------------------------------------------------------------------------------------------------

export enum SpecialBasedOnSite {
  CYCLE_TRAVAIL,
  RCR,
}

export const specialKeysBasedOnSite = new Map([
  ["cycle_travail", SpecialBasedOnSite.CYCLE_TRAVAIL],
  ["RCR", SpecialBasedOnSite.RCR],
]);

export function buildCheckerBasedOnSite(
  special: SpecialBasedOnSite,
): SiteAccessChecker {
  switch (special) {
    case SpecialBasedOnSite.CYCLE_TRAVAIL:
      return new CycleTravailEnabled();
    case SpecialBasedOnSite.RCR:
      return new RcrEnabled();
  }
}

// -------------------------------------------------------------------------------------------------

/*

On ignore délibérément les trois "specials" suivants :

1. 'domain_admin'
  Vérifie que l'utilisateur est admin du domaine indiqué dans l'URL
  => Inutile pour le menu principal, sera géré au niveau des pages en React

2. 'multi_sites'
  Verrue utilisée uniquement pour la consolidation des plannings :
  - Coté dojo on vérifie simplement que la première des souscriptions (`subscription_key`) est
    présente sur plusieurs sites.
  - Côté React on ignorera ce `special` pour l'affichage du menu ou de la page car ça ne relève pas
    vraiment des permissions mais de l'affichage de la page : pourquoi ne pas consolider un seul
    site après tout ? Quitte à afficher un message : "Consolidation impossible sur un seul site".
  **TODO** : Du coup il faudra vérifier/modifier le comportement de la page correspondant à la clef
  `rh_planning_multisitesview` quand on clique sur le menu en React

3. 'public'
  Bien qu'il soit géré en dojo par PageSecurityChecker.js ce spécial n'apparait pas dans le fichier
  pages.json
*/
export const ignoreSpecialKeys = ["public", "domain_admin", "multi_sites"];

export function unknownSpecialKey(key: string): boolean {
  return ![specialKeysBasedOnSite, specialKeysBasedOnUser].some((m) =>
    m.has(key),
  );
}
