/******************************
 * GENERATED FILE, DO NOT EDIT *
 *******************************/ import type {
  ReactNode,
  SVGProps,
} from "react";
export default function IconUserCircle(
  props: SVGProps<SVGSVGElement>
): ReactNode {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16px"
      height="16px"
      fill="none"
      viewBox="0 0 26 26"
      color="currentColor"
      {...props}
    >
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit={10}
        strokeWidth={1.2}
        d="M25.238 13c0 6.759-5.479 12.238-12.238 12.238C6.24 25.238.762 19.76.762 13 .762 6.24 6.24.762 13 .762S25.238 6.24 25.238 13"
      />
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit={10}
        strokeWidth={1.2}
        d="M17.895 11.368a4.895 4.895 0 1 1-9.79 0 4.895 4.895 0 0 1 9.79 0"
      />
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit={10}
        strokeWidth={1.2}
        d="M5.698 22.822a7.344 7.344 0 0 1 14.604 0"
      />
    </svg>
  );
}
