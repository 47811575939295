import type ColumnConfig from "@/layout/header/searchBar/model/search/result/ColumnConfig";
import type SearchConfig from "@/layout/header/searchBar/model/search/SearchConfig";

export default class ResultConfig {
  constructor(
    public readonly columns: ColumnConfig[],
    // TODO: Why not remove field below and always redirect if one result only ?
    public readonly redirectIfSingleResult = true,
    public parent?: SearchConfig,
  ) {
    this.columns.forEach((c) => (c.parent = this));
  }
}
