import ThreeDotsOverlay from "@/shared/components/generic/loading/ThreeDotsOverlay";
import { AppUserInfoContext } from "@/shared/model/user/AppUserInfoContext";
import { goToLoginWithTarget } from "@/shared/model/user/Logout";
import {
  buildAppUserInfoFromLocalStorage,
  buildAppUserInfoFromObject,
} from "@/shared/model/user/UserJsCache";
import "@/shared/style/design_system/Fonts.scss";
import { handleError, handleSuccess } from "@/shared/utils/AxiosUtils";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { ReactQueryDevtools } from "@tanstack/react-query-devtools";
import axios from "axios";
import { Suspense, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import {
  createBrowserRouter,
  Navigate,
  RouterProvider,
} from "react-router-dom";
import "./i18n";

import { AppUserInfo } from "@/shared/model/user/AppUserInfo";
import { routes } from "./routes";

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      retry: 1,
      staleTime: 1000 * 5, // by default, fetched data are considered "fresh enough" for 5 seconds.
    },
  },
});

// Router is static, hence built once and for all, *not* inside App component.
const router = createBrowserRouter(routes);

axios.interceptors.response.use(handleSuccess, handleError);

export default function App(): React.ReactElement {
  if (import.meta.env.DEV) {
    console.debug("### Rendering App ###");
  }

  const [appUserInfo, setAppUserInfo] = useState(
    buildAppUserInfoFromLocalStorage(),
  );

  const { t, i18n } = useTranslation();

  useEffect(() => {
    async function setupLanguage(): Promise<void> {
      const language = appUserInfo.userInfo?.language;
      if (language !== i18n.language) {
        await i18n.changeLanguage(language);
      }
    }
    setupLanguage().catch((e: unknown) => {
      console.error(e);
    });
  }, [i18n, appUserInfo.userInfo?.language]);

  useEffect(() => {
    const channel = new BroadcastChannel("mycawanBackoffice");

    channel.onmessage = (e): void => {
      const { action, payload } = e.data as { action: string; payload: object };
      if (action === "logout") {
        setAppUserInfo(new AppUserInfo(false));
      } else if (action === "backendDataRefreshed") {
        console.debug("Backend refreshed !");
        setAppUserInfo(buildAppUserInfoFromObject(payload));
      }
    };
    return (): void => {
      channel.close();
    };
  }, []);

  if (!appUserInfo.loggedIn) {
    goToLoginWithTarget();
  } else {
    const target = new URLSearchParams(window.location.search).get("target");
    if (target != null) {
      return <Navigate to={target} replace />;
    }
  }

  const appContent = (
    <QueryClientProvider client={queryClient}>
      <AppUserInfoContext.Provider value={appUserInfo}>
        <RouterProvider router={router} />
        <ReactQueryDevtools
          initialIsOpen={false}
          buttonPosition="bottom-right"
        />
      </AppUserInfoContext.Provider>
    </QueryClientProvider>
  );

  const loginLink = <a href="/">{t("common:messages.pleaseReconnect")}</a>;

  return (
    <Suspense fallback={<ThreeDotsOverlay />}>
      <div className="body medium">
        {appUserInfo.loggedIn ? appContent : loginLink}
      </div>
    </Suspense>
  );
}
