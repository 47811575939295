/******************************
 * GENERATED FILE, DO NOT EDIT *
 *******************************/ import type {
  ReactNode,
  SVGProps,
} from "react";
export default function IconPadlock(props: SVGProps<SVGSVGElement>): ReactNode {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16px"
      height="16px"
      viewBox="0 0 16 16"
      color="currentColor"
      {...props}
    >
      <path
        d="M8 .571q-1.509 0-2.884.59-1.375.589-2.37 1.585-.996.995-1.585 2.37Q.57 6.491.57 8t.59 2.884 1.585 2.37q.995.996 2.37 1.585 1.375.59 2.884.59t2.884-.59 2.37-1.585q.996-.995 1.585-2.37.59-1.375.59-2.884t-.59-2.884-1.585-2.37q-.995-.996-2.37-1.585Q9.509.57 8 .57M8 0q1.625 0 3.107.634t2.554 1.705 1.705 2.554T16 8t-.634 3.107-1.705 2.554-2.554 1.705T8 16t-3.107-.634-2.554-1.705-1.705-2.554T0 8t.634-3.107 1.705-2.554T4.893.634 8 0M4.429 7.429q.142 0 .142.142v4.286q0 .143-.142.143h-.286Q4 12 4 11.857V7.571q0-.142.143-.142zM8 8q.473 0 .808.335t.335.808q0 .312-.156.571-.157.26-.416.411v1.018q0 .125-.08.205t-.205.08h-.572q-.125 0-.205-.08t-.08-.205v-1.018q-.26-.152-.416-.41-.156-.26-.156-.572 0-.473.335-.808T8 8m0-6.857q1.866 0 3.442.92t2.496 2.495.92 3.442-.92 3.442-2.496 2.496-3.442.92-3.442-.92-2.495-2.496T1.143 8t.92-3.442 2.495-2.495T8 1.143M4.857 5.429v.857q0 .125.08.205t.206.08h.571q.125 0 .206-.08T6 6.286v-.857q0-.83.585-1.416Q7.17 3.43 8 3.43t1.415.584T10 5.43v.857q0 .125.08.205t.206.08h.571q.125 0 .206-.08t.08-.205v-.857q0-1.304-.92-2.224T8 2.285t-2.223.92-.92 2.224M12.571 12V7.429q0-.233-.17-.402-.169-.17-.401-.17H4q-.232 0-.402.17t-.17.402V12q0 .232.17.402t.402.17h8q.232 0 .402-.17t.17-.402"
        fill="currentColor"
      />
    </svg>
  );
}
