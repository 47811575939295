import type ModuleSearchConfig from "@/layout/header/searchBar/model/search/ModuleSearchConfig";
import { SearchI18nKeyComposer } from "@/layout/header/searchBar/model/search/SearchesI18N";
import { ModuleSearch } from "@/layout/header/searchBar/search-panel/global-search/module-search/ModuleSearch";
import { GlobalSearchContext } from "@/layout/header/searchBar/GlobalSearchContext";
import { AppUserInfoContext } from "@/shared/model/user/AppUserInfoContext";
import { useContext, type ReactNode } from "react";
import { Trans, useTranslation } from "react-i18next";
import "./GlobalSearch.scss";

interface Properties {
  modules: ModuleSearchConfig[];
}

export function GlobalSearch(props: Properties): ReactNode {
  const { i18n } = useTranslation("globalSearch");
  const { userInput, searchConfig } = useContext(GlobalSearchContext);
  const appUserInfo = useContext(AppUserInfoContext);

  const i18nKeys = new SearchI18nKeyComposer(i18n.exists);
  const errorMessage = invalidInputMessage();

  return (
    <div className="global-search">
      {errorMessage !== "" && (
        <div className="error-message">{errorMessage}</div>
      )}
      <>
        {props.modules
          .filter((m) => m.userCanAccess(appUserInfo))
          .map((m) => (
            <ModuleSearch key={m.i18nKey} searchConfig={m} />
          ))}
      </>
    </div>
  );

  function invalidInputMessage(): ReactNode {
    const config = searchConfig?.inputConfig;
    if (config === undefined || config.validInput(userInput)) {
      return "";
    } else if (config.numericInput) {
      return (
        <Trans
          i18nKey={i18nKeys.inputIsNotANumber()}
          values={{ input: userInput }}
        />
      );
    } else {
      return (
        <Trans
          i18nKey={i18nKeys.inputTooShort()}
          values={{ length: config.inputMinLength }}
        />
      );
    }
  }
}
