import { useEffect } from "react";

export function useKeyboardHandler(handler: (e: KeyboardEvent) => void): void {
  useEffect(() => {
    function handleKeyDown(e: KeyboardEvent): void {
      handler(e);
    }
    window.addEventListener("keydown", handleKeyDown);
    return (): void => {
      window.removeEventListener("keydown", handleKeyDown);
    };
  });
}
