import type { UserDomainPermissions } from "../UserPermissions";
import { CheckMode, type DomainAccessChecker } from "./AccessCheckersApi";

export class SecurityKeyChecker implements DomainAccessChecker {
  constructor(
    private readonly checkMode: CheckMode,
    private readonly securityKeys: string[],
  ) {}

  public checkDomain(udp: UserDomainPermissions): boolean {
    if (udp.isAdmin) {
      return true;
    }
    switch (this.checkMode) {
      case CheckMode.ANY:
        return udp.hasAnyPermission(this.securityKeys);
      case CheckMode.ALL:
        return udp.hasEveryPermissions(this.securityKeys);
    }
  }
}

export class DomainAdminChecker implements DomainAccessChecker {
  public checkDomain(dup: UserDomainPermissions): boolean {
    return dup.isAdmin;
  }
}
