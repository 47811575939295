export function capitalize(str: string): string {
  if (str === "") return str;
  return str.charAt(0).toUpperCase() + str.slice(1);
}

export function uncapitalize(str: string): string {
  if (str === "") return str;
  return str.charAt(0).toLowerCase() + str.slice(1);
}

export function removeDiacritics(str: string): string {
  return str.normalize("NFD").replace(/\p{Diacritic}/gu, "");
}

export function cleanWhiteSpaces(str: string): string {
  return str.trim().replace(/\s+/, " ");
}

export function normalizedCharacters(str: string): string {
  return removeDiacritics(str).toLocaleLowerCase();
}

export function normalizedKeywords(str: string): string[] {
  return normalizedCharacters(cleanWhiteSpaces(str))
    .split(" ")
    .filter((s) => s !== "");
}

export function kebabToCamel(str: string): string {
  const words = str
    .toLowerCase()
    .split("-")
    .filter((s) => s !== "");
  return [words.shift(), ...words.map(capitalize)].join("");
}

export function kebabToPascal(str: string): string {
  return capitalize(kebabToCamel(str));
}

export function isDigitsOnly(str: string): boolean {
  return str !== "" && str.split("").every((c) => "0123456789".includes(c));
}

export function indexesOf(haystack: string, needle: string): number[] {
  let startAt = 0;
  const res = [];
  while (startAt < haystack.length) {
    const idx = haystack.indexOf(needle, startAt);
    if (idx === -1) {
      startAt = haystack.length;
    } else {
      res.push(idx);
      startAt = idx + needle.length;
    }
  }
  return res;
}
