export default class Menu {
  readonly key: string;
  readonly targetPage?: string;
  readonly children: Menu[];
  parent?: Menu;

  constructor(key: string, children: Menu[] = [], targetPage?: string) {
    this.key = key;
    this.targetPage = targetPage;
    this.children = children;
    children.forEach((c) => {
      c.parent = this;
    });
  }

  hasChildren(): boolean {
    return this.children.length > 0;
  }

  noChildren(): boolean {
    return !this.hasChildren();
  }

  hasTargetPage(): boolean {
    return this.targetPage !== undefined;
  }

  getTargetPage(): string {
    if (this.targetPage === undefined) {
      throw new Error(`Menu item '${this.key}' has no target page`);
    }
    return this.targetPage;
  }

  getParent(): Menu {
    if (this.parent === undefined) {
      throw new Error(`Menu '${this.key}' has no parent`);
    }
    return this.parent;
  }

  pathFromRoot(): Menu[] {
    if (this.parent === undefined) {
      return [this];
    }
    return [...this.parent.pathFromRoot(), this];
  }

  lookup(matcher: (m: Menu) => boolean): Menu | undefined {
    if (matcher(this)) {
      return this as Menu;
    }
    return this.children
      .map((c) => c.lookup(matcher))
      .find((m) => m !== undefined);
  }

  listTree(): Menu[] {
    return [this, ...this.children.map((c) => c.listTree()).flat()];
  }
}
