import {
  jsonToBookmark,
  type Bookmark,
} from "@/layout/header/menu/bookmarks/services/dtos/Bookmark";
import type { LegacyResponse } from "@/shared/utils/LegacyResponse";
import type { UndefinedInitialDataOptions } from "@tanstack/react-query";
import axios from "axios";

async function getBookmarks(): Promise<Bookmark[]> {
  const {
    data: {
      body: { bookmarks },
    },
  } = await axios.get<LegacyResponse>("/rest/currentuser/bookmarks");
  return (bookmarks as object[]).map(jsonToBookmark);
}

export function bookmarksQuery(): UndefinedInitialDataOptions<Bookmark[]> {
  return {
    queryKey: ["bookmarks"],
    queryFn: async () => await getBookmarks(),
    staleTime: 1000 * 60 * 2,
  };
}
