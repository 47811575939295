// eslint-disable-next-line import/namespace
import { fromZonedTime } from "date-fns-tz";

const legacyTimeZone = "Europe/Paris";
const regexpFrenchDate = new RegExp("^\\d{2}/\\d{2}/\\d{4}$");
const regexpIsoFrenchDate = new RegExp("^\\d{4}-\\d{2}-\\d{2}$");
const regexpFrenchDateTime = new RegExp(
  "^\\d{2}/\\d{2}/\\d{4} \\d{2}:\\d{2}:\\d{2}$",
);

/**
 * @deprecated Use only for legacy enpoints outputs
 * @param s Date au format dd/MM/yyyy dans le fuseau implicite "Europe/Paris"
 * @returns
 */
export function legacyParseFrenchDate(s: string): Date {
  checkInput(s, regexpFrenchDate);
  // eslint-disable-next-line @typescript-eslint/no-deprecated
  return legacyParseIsoFrenchDate(frToIsoDate(s));
}

/**
 * @deprecated Use only for legacy endpoints outputs
 * @param s String au format yyyy-MM-dd dans le fuseau implicite "Europe/Paris"
 * @returns Date object
 */
export function legacyParseIsoFrenchDate(s: string): Date {
  checkInput(s, regexpIsoFrenchDate);
  return fromZonedTime(s, legacyTimeZone);
}

/**
 * @deprecated Use only for legacy endpoints outputs
 * @param s String au format dd/MM/yyyy HH:mm:ss dans le fuseau implicite "Europe/Paris"
 * @returns Date object
 */
export function legacyParseFrenchDateTime(s: string): Date {
  checkInput(s, regexpFrenchDateTime);
  const isoDate = frToIsoDate(s.substring(0, 10));
  const time = s.substring(11);
  return fromZonedTime(`${isoDate}T${time}`, legacyTimeZone);
}

function frToIsoDate(s: string): string {
  return `${s.substring(6, 10)}-${s.substring(3, 5)}-${s.substring(0, 2)}`;
}

function checkInput(s: string, re: RegExp): void {
  if (!re.test(s)) {
    throw new Error(`Invalid input string: '${s}'`);
  }
}
