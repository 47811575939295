import {
  jsonToResultRow,
  type ResultRow,
} from "@/layout/header/searchBar/result-popup/services/dtos/ResultRow";
import type { LegacyResponse } from "@/shared/utils/LegacyResponse";
import type { UndefinedInitialDataOptions } from "@tanstack/react-query";
import axios from "axios";

async function search(
  scope: string,
  searchString: string,
): Promise<ResultRow[]> {
  const {
    data: {
      body: { payload: jsonResultRows },
    },
  } = await axios.post<LegacyResponse>("/rest/search", {
    scope,
    searchString,
  });
  return (jsonResultRows as object[]).map(jsonToResultRow);
}

export function searchQuery(
  scope: string,
  searchString: string,
): UndefinedInitialDataOptions<ResultRow[]> {
  return {
    queryKey: ["search", scope, searchString],
    queryFn: async () => await search(scope, searchString),
    staleTime: 10 * 1000,
  };
}
