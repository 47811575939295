import type { Domain } from "@/shared/model/user/UserDomains";
import "@/layout/header/menu/components/drop-down/MainHeaderDropDownItem.scss";
import type { ReactNode } from "react";

export interface Properties {
  domain: Domain;
}

export function MainHeaderDropDownItemDomain(props: Properties): ReactNode {
  const logoSize = "24px";
  return (
    <li className="main-header-drop-down-item">
      <a
        href={`/content/domain/?task=viewinfoform&idDomain=${props.domain.id}`}
      >
        <div className="item">
          <img
            style={{
              width: logoSize,
              height: logoSize,
              objectFit: "contain",
            }}
            src={`/rest/domain/logo/${props.domain.id}`}
          />
          <div>{props.domain.name}</div>
        </div>
      </a>
    </li>
  );
}
