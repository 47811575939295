/******************************
 * GENERATED FILE, DO NOT EDIT *
 *******************************/ import type {
  ReactNode,
  SVGProps,
} from "react";
export default function IconUserFill(
  props: SVGProps<SVGSVGElement>
): ReactNode {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16px"
      height="16px"
      viewBox="0 0 16 16"
      color="currentColor"
      {...props}
    >
      <path
        d="M14.667 13.24q0 1.135-.651 1.948T12.448 16H3.552q-.917 0-1.568-.812-.65-.813-.65-1.948 0-.886.088-1.672.088-.787.328-1.584t.61-1.364.979-.927q.609-.36 1.4-.36Q6.105 8.667 8 8.667t3.26-1.334q.792 0 1.401.36.61.36.98.927t.609 1.364.328 1.584q.089.786.089 1.672M12 4q0 1.656-1.172 2.828T8 8 5.172 6.828 4 4t1.172-2.828T8 0t2.828 1.172T12 4"
        fill="currentColor"
      />
    </svg>
  );
}
