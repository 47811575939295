import SearchConfig from "@/layout/header/searchBar/model/search/SearchConfig";
import InputConfig from "@/layout/header/searchBar/model/search/input/InputConfig";
import ModuleSearchConfig from "@/layout/header/searchBar/model/search/ModuleSearchConfig";
import ColumnConfig from "@/layout/header/searchBar/model/search/result/ColumnConfig";
import ResultConfig from "@/layout/header/searchBar/model/search/result/ResultConfig";

// -----------------------------------------------------------------------------
// Configurations des recherches Ressources humaines
// -----------------------------------------------------------------------------

const HUMAN_RESOURCES_SEARCHES = new ModuleSearchConfig("humanResources", [
  // Recherche employés par code
  new SearchConfig(
    "employeesByCode",
    "employesByCode",
    "RH_ETAT_CIVIL_VISU",
    new InputConfig(false, 1),
    new ResultConfig([
      new ColumnConfig("domain"),
      new ColumnConfig("site"),
      new ColumnConfig("code", true),
      new ColumnConfig("firstAndLastName"),
    ]),
  ),
  // Recherche employés par prénom/nom/tél.
  new SearchConfig(
    "employeesOther",
    "employesByTelNomPrenom",
    "RH_ETAT_CIVIL_VISU",
    new InputConfig(false, 3),
    new ResultConfig(
      [
        new ColumnConfig("domain"),
        new ColumnConfig("site"),
        new ColumnConfig("code"),
        new ColumnConfig("firstAndLastName", true),
      ],
      false,
    ),
  ),
]);

export default HUMAN_RESOURCES_SEARCHES;
