import type { ReactNode } from "react";
import "./ThreeDotsOverlay.scss";
import ThreeDots from "./ThreeDots";

export default function ThreeDotsOverlay(): ReactNode {
  return (
    <div className="generic-waiting">
      <div className="spacer"></div>
      <ThreeDots />
    </div>
  );
}
