import { MainLayout } from "@/layout/MainLayout";
import type { ReactNode } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import "./PageNotFound.scss";

export default function PageNotFound(): ReactNode {
  const { t } = useTranslation();
  return (
    <MainLayout>
      <div className="page-not-found">
        <h3>{t("common:defaultPages.notFound")}</h3>
        <Link to="/content/currentuser/?task=viewportal" reloadDocument={true}>
          {t("common:messages.backHome")}
        </Link>
      </div>
    </MainLayout>
  );
}
