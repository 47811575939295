import type Menu from "@/layout/sticky-column/main-menu/model/Menu";
import { ALL_PAGES } from "@/shared/model/pages-management/AllPages";
import type { Page } from "@/shared/model/pages-management/ApplicationPages";

// This is a bad smell below (Menu instance should be able to implement this function).
// TODO: Try to change type of Menu.targetPage from string? to Page?

export function getMenuTargetPage(menu: Menu): Page | undefined {
  return menu.hasTargetPage() ? ALL_PAGES.get(menu.getTargetPage()) : undefined;
}
