type KeyType = number | string;

export function sortByKey<ItemType>(
  array: ItemType[],
  keyFn: (a: ItemType) => KeyType,
  reversed = false,
): void {
  array.sort(compareFn(keyFn, reversed));
}

export function compareFn<T>(
  keyFn: (t: T) => KeyType,
  reversed = false,
): (a: T, b: T) => number {
  return (a: T, b: T): number => {
    const ka = keyFn(a);
    const kb = keyFn(b);
    if (ka < kb) return reversed ? 1 : -1;
    if (kb < ka) return reversed ? -1 : 1;
    return 0;
  };
}

export function range(limit: number, start?: number, step?: number): number[] {
  const res: number[] = [];
  for (let i = start ?? 0; i < limit; i += step ?? 1) {
    res.push(i);
  }
  return res;
}
