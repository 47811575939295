import type { AppUserInfo } from "../AppUserInfo";
import type { UserFlags } from "../UserPermissions";
import type { UserAccessChecker } from "./AccessCheckersApi";

export class UserFlagsChecker implements UserAccessChecker {
  constructor(private readonly required: UserFlags) {}

  public checkUser(aui: AppUserInfo): boolean {
    const userFlags = aui.permissions?.flags;
    return userFlags !== undefined && this.checkFlags(userFlags);
  }

  private checkFlags(flags: UserFlags): boolean {
    return (
      this.checkFlag(this.required.isBetaTester(), flags.isBetaTester()) &&
      this.checkFlag(this.required.isSalesPerson(), flags.isSalesPerson()) &&
      this.checkFlag(this.required.isSupport(), flags.isSupport()) &&
      this.checkFlag(this.required.isDevelopper(), flags.isDevelopper())
    );
  }

  private checkFlag(required: boolean, present: boolean): boolean {
    return required ? present : true;
  }
}

export class VerifiedEmailChecker implements UserAccessChecker {
  public checkUser(aui: AppUserInfo): boolean {
    return aui.userInfo?.email.verified ?? false;
  }
}

export class UserLoggedInChecker implements UserAccessChecker {
  public checkUser(aui: AppUserInfo): boolean {
    return aui.loggedIn;
  }
}

export class AnyDomainAdminChecker implements UserAccessChecker {
  public checkUser(aui: AppUserInfo): boolean {
    return (aui.permissions?.domainsPermissions() ?? []).some(
      (udp) => udp.isAdmin,
    );
  }
}
