/******************************
 * GENERATED FILE, DO NOT EDIT *
 *******************************/ import type {
  ReactNode,
  SVGProps,
} from "react";
export default function IconIdCard(props: SVGProps<SVGSVGElement>): ReactNode {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16px"
      height="16px"
      viewBox="0 0 16 16"
      color="currentColor"
      {...props}
    >
      <path
        d="M8 9.836q0 .5-.29.832Q7.423 11 7 11H3q-.422 0-.71-.332Q2 10.336 2 9.836t.07-.918.23-.805q.16-.386.473-.61.313-.222.758-.222.047.031.235.14.187.11.293.169.105.058.277.137.172.078.336.113T5 7.875t.328-.035.336-.113.277-.137.293-.168.235-.14q.445 0 .758.222.312.223.472.61.16.386.23.804T8 9.836M6.773 5.773q0 .735-.52 1.254-.519.52-1.253.52t-1.254-.52-.52-1.254.52-1.253T5 4t1.254.52.52 1.253M14 9.25v.5q0 .11-.07.18t-.18.07h-4.5q-.11 0-.18-.07T9 9.75v-.5q0-.11.07-.18T9.25 9h4.5q.11 0 .18.07t.07.18m0-1.969v.438q0 .117-.082.199t-.2.082H9.282q-.117 0-.199-.082T9 7.718v-.437q0-.117.082-.199t.2-.082h4.437q.117 0 .199.082t.082.2m0-2.031v.5q0 .11-.07.18t-.18.07h-4.5q-.11 0-.18-.07T9 5.75v-.5q0-.11.07-.18T9.25 5h4.5q.11 0 .18.07t.07.18m1 7.5v-9.5q0-.102-.074-.176T14.75 3H1.25q-.102 0-.176.074T1 3.25v9.5q0 .102.074.176T1.25 13H4v-.75q0-.11.07-.18t.18-.07h.5q.11 0 .18.07t.07.18V13h6v-.75q0-.11.07-.18t.18-.07h.5q.11 0 .18.07t.07.18V13h2.75q.102 0 .176-.074T15 12.75m1-9.5v9.5q0 .516-.367.883T14.75 14H1.25q-.516 0-.883-.367T0 12.75v-9.5q0-.516.367-.883T1.25 2h13.5q.516 0 .883.367T16 3.25"
        fill="currentColor"
      />
    </svg>
  );
}
