/******************************
 * GENERATED FILE, DO NOT EDIT *
 *******************************/ import type {
  ReactNode,
  SVGProps,
} from "react";
export default function IconAtSign(props: SVGProps<SVGSVGElement>): ReactNode {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16px"
      height="16px"
      viewBox="0 0 16 16"
      color="currentColor"
      {...props}
    >
      <path
        d="M10.125 6.74q0-1.126-.557-1.76-.558-.636-1.537-.636-.656 0-1.291.317-.636.318-1.146.88T4.766 6.97t-.318 1.875q0 1.166.557 1.802.558.635 1.568.635 1 0 1.833-.693.834-.692 1.276-1.729.443-1.036.443-2.12M16 8q0 1.156-.385 2.052-.386.896-1.026 1.406-.641.51-1.37.776t-1.51.287q-.063 0-.162.005-.1.005-.172.005-.99 0-1.48-.552-.29-.344-.343-.864-.542.687-1.37 1.145-.828.459-1.807.459-1.677 0-2.599-.995t-.922-2.807q0-1.636.688-3.021t1.864-2.193 2.563-.807q.906 0 1.614.37t1.105 1.036l.02-.198.115-.583q.01-.063.057-.125.047-.063.1-.063h1.228q.052 0 .136.115.052.052.031.167l-1.25 6.395q-.052.25-.052.5 0 .407.13.542t.464.135q.291-.01.593-.057t.76-.25q.46-.203.803-.52.344-.318.594-.933T14.667 8q0-3.042-1.813-4.854T8 1.333q-1.354 0-2.589.532-1.234.53-2.125 1.421-.89.891-1.421 2.125Q1.333 6.646 1.333 8t.532 2.589 1.421 2.125 2.125 1.421q1.235.532 2.589.532 2.375 0 4.219-1.5.114-.094.25-.084.135.01.218.125l.428.51q.083.126.072.25-.02.136-.125.23-1.062.864-2.37 1.333Q9.386 16 8 16q-1.625 0-3.104-.635-1.48-.636-2.552-1.709T.635 11.104Q0 9.625 0 8t.635-3.104 1.709-2.552T4.896.635 8 0q3.583 0 5.792 2.208T16 8"
        fill="currentColor"
      />
    </svg>
  );
}
