import { splitMatching } from "@/shared/utils/StringMatchesSplitter";
import { normalizedCharacters } from "@/shared/utils/Strings";
import type { ReactNode } from "react";

export interface Properties {
  text: string;
  normalizedKeywords: string[];
}

export function WordsHighlights(props: Properties): ReactNode {
  const fragments = splitMatching(
    normalizedCharacters(props.text),
    props.normalizedKeywords,
  );
  return (
    <span>
      {fragments
        .map(({ start, end, matched }) => ({
          str: props.text.substring(start, end),
          matched,
        }))
        .map(({ str, matched }, index) => (
          <span key={index}>
            {matched ?
              <strong>{str}</strong>
            : str}
          </span>
        ))}
    </span>
  );
}
