import type { ReactNode } from "react";
import "./ThreeDots.scss";

export default function ThreeDots(): ReactNode {
  return (
    <div className="frame">
      <div className="dots">
        <div className="dot1"></div>
        <div className="dot2"></div>
        <div className="dot3"></div>
      </div>
    </div>
  );
}
