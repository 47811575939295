/******************************
 * GENERATED FILE, DO NOT EDIT *
 *******************************/ import type {
  ReactNode,
  SVGProps,
} from "react";
export default function IconWorldPointer(
  props: SVGProps<SVGSVGElement>
): ReactNode {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16px"
      height="16px"
      fill="none"
      viewBox="0 0 22 22"
      color="currentColor"
      {...props}
    >
      <path
        fill="currentColor"
        fillRule="evenodd"
        d="M9.68 17.665q.12-.004.24-.005l-.01-.02c.33-.03.62.23.64.56s-.23.62-.56.64c-.19.02-.37.02-.56.02-4.05 0-7.51-2.57-8.84-6.17a.6.6 0 0 1-.18-.43q.001-.015.005-.025.005-.011.005-.025C.15 11.33 0 10.4 0 9.43s.15-1.9.42-2.78C.41 6.63.41 6.62.41 6.6c0-.17.08-.32.19-.43C1.93 2.58 5.38 0 9.44 0c5.2 0 9.43 4.24 9.44 9.45 0 .18-.01.37-.02.55-.02.33-.3.57-.63.56a.594.594 0 0 1-.56-.63c.01-.16.01-.33.01-.49 0-.78-.12-1.53-.32-2.24h-4.47c.06.69.1 1.4.1 2.1 0 .33-.27.6-.6.6-.34 0-.6-.27-.6-.6 0-.74-.04-1.44-.1-2.1H7.2c-.06.7-.1 1.45-.1 2.24s.04 1.53.1 2.23h2.15c.33 0 .6.27.6.6s-.27.6-.6.6H7.33c.44 3 1.39 4.8 2.11 4.8q.12-.001.24-.005M1.52 11.67h4.47c-.07-.73-.1-1.47-.1-2.23s.04-1.51.1-2.24H1.52c-.2.72-.32 1.47-.32 2.24s.12 1.52.32 2.23M9.43 1.2C8.72 1.21 7.77 3 7.33 6h4.2c-.44-3-1.39-4.8-2.1-4.8M12.75 6h4.16a8.25 8.25 0 0 0-5.51-4.55c.65 1.1 1.1 2.72 1.35 4.55M6.11 6c.25-1.83.7-3.45 1.35-4.55C5.01 2.05 3 3.75 1.96 6zm0 6.87H1.95a8.29 8.29 0 0 0 5.51 4.56c-.65-1.1-1.1-2.73-1.35-4.56m7.38-1.09 7.07 3.21c.44.2.72.62.72 1.1.01.48-.25.91-.68 1.12l-2.2 1.1-1.11 2.21c-.21.42-.63.68-1.1.68h-.02c-.48-.01-.9-.28-1.1-.72l-3.21-7.07c-.22-.47-.12-1.01.25-1.38s.91-.47 1.38-.25m4.38 5.46 2.19-1.1h.01l.25-.6-.25.55L13 12.88l3.17 7.11 1.15-2.2c.11-.24.31-.43.55-.55"
        clipRule="evenodd"
      />
    </svg>
  );
}
