import AdministrationMenu from "@/layout/header/menu/administration/AdministrationMenu";
import BookmarksMenu from "@/layout/header/menu/bookmarks/BookmarksMenu";
import DomainsManagementMenu from "@/layout/header/menu/domains/DomainsManagementMenu";
import UserAccountMenu from "@/layout/header/menu/user-account/UserAccountMenu";
import { AppUserInfoContext } from "@/shared/model/user/AppUserInfoContext";
import { type ReactNode, useContext, useEffect, useRef, useState } from "react";
import "./MainHeaderMenu.scss";

export function MainHeaderMenu(): ReactNode {
  const appUserInfo = useContext(AppUserInfoContext);
  const rootDivRef = useRef<HTMLDivElement>(null);
  const [activeMenuKey, setActiveMenuKey] = useState<string>("");

  useEffect(() => {
    const outsideClickHandler = (evt: Event): void => {
      const targetNode = evt.target as Node;
      if (
        targetNode !== rootDivRef.current &&
        !(rootDivRef.current?.contains(targetNode) ?? false)
      ) {
        setActiveMenuKey("");
      }
    };

    if (activeMenuKey !== "") {
      window.addEventListener("click", outsideClickHandler);
    } else {
      window.removeEventListener("click", outsideClickHandler);
    }

    return (): void => {
      window.removeEventListener("click", outsideClickHandler);
    };
  }, [activeMenuKey, setActiveMenuKey]);

  return (
    <div className="main-header-menu" ref={rootDivRef}>
      <AdministrationMenu
        menuKey="administration"
        activeMenuKey={activeMenuKey}
        flags={appUserInfo.permissions?.flags}
        onClick={setActiveMenuKey}
      />
      <BookmarksMenu
        menuKey="bookmarks"
        activeMenuKey={activeMenuKey}
        onClick={setActiveMenuKey}
      />
      <DomainsManagementMenu
        menuKey="domainsManagement"
        activeMenuKey={activeMenuKey}
        onClick={setActiveMenuKey}
        domainsWhereAdmin={appUserInfo.permissions?.domainsWhereAdmin()}
      />
      <UserAccountMenu
        menuKey="userAccount"
        activeMenuKey={activeMenuKey}
        onClick={setActiveMenuKey}
        userInfo={appUserInfo.userInfo}
      />
    </div>
  );
}
