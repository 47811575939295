/******************************
 * GENERATED FILE, DO NOT EDIT *
 *******************************/ import type {
  ReactNode,
  SVGProps,
} from "react";
export default function IconArrowExitRight(
  props: SVGProps<SVGSVGElement>
): ReactNode {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16px"
      height="16px"
      fill="none"
      viewBox="0 0 16 16"
      color="currentColor"
      {...props}
    >
      <path
        fill="currentColor"
        d="M6.53 13.551q0 .04.01.204t.006.27q-.005.108-.03.24-.026.133-.103.2-.076.066-.209.066H2.94q-1.215 0-2.077-.863Q0 12.806 0 11.592V4.408q0-1.214.862-2.076t2.077-.863h3.265q.133 0 .23.097t.097.23q0 .04.01.204t.005.27q-.005.108-.03.24-.026.133-.103.2-.076.066-.209.066H2.94q-.674 0-1.153.48-.48.479-.48 1.152v7.184q0 .673.48 1.153t1.153.48h3.183l.118.01.117.03.082.056.071.092zM16 8q0 .265-.194.46l-5.55 5.55q-.195.194-.46.194t-.46-.194q-.193-.194-.193-.459v-2.939H4.57q-.265 0-.459-.194t-.194-.459V6.041q0-.265.194-.46.194-.193.46-.193h4.57V2.449q0-.265.195-.46.194-.193.459-.193t.46.194l5.55 5.55Q16 7.736 16 8"
      />
    </svg>
  );
}
