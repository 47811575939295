import type { AppUserInfo } from "../AppUserInfo";
import type { UserDomainPermissions } from "../UserPermissions";
import type { Site } from "../UserDomains";

export class PageAccessChecker {
  private readonly children: PageAccessChecker[] = [];
  constructor(
    private readonly userChecker?: UserAccessChecker,
    private readonly domainChecker?: DomainAccessChecker,
  ) {}

  check(aui: AppUserInfo): boolean {
    return (
      this.userAllowed(aui) &&
      this.anyDomainAllowed(aui) &&
      this.checkChildren(aui)
    );
  }

  addChild(child: PageAccessChecker): void {
    this.children.push(child);
  }

  private userAllowed(aui: AppUserInfo): boolean {
    if (this.userChecker === undefined) {
      return true;
    }
    return this.userChecker.checkUser(aui);
  }

  private anyDomainAllowed(aui: AppUserInfo): boolean {
    if (this.domainChecker === undefined) {
      return true;
    }
    return (aui.permissions?.domainsPermissions() ?? []).some((udp) =>
      this.domainChecker?.checkDomain(udp),
    );
  }

  private checkChildren(aui: AppUserInfo): boolean {
    if (this.children.length === 0) {
      return true;
    }
    return this.children.some((c) => c.check(aui));
  }
}

export enum CheckMode {
  ANY,
  ALL,
}

// User level --------------------------------------------------------------------------------------

// TODO: Could CompositeXxxxxCheckers be factorized with some TypeScript generics ?

export interface UserAccessChecker {
  checkUser: (aui: AppUserInfo) => boolean;
}

export class CompositeUserAccessChecker implements UserAccessChecker {
  constructor(
    private readonly childrenCheckMode: CheckMode = CheckMode.ALL,
    private readonly children: UserAccessChecker[] = [],
  ) {}

  checkUser(aui: AppUserInfo): boolean {
    switch (this.childrenCheckMode) {
      case CheckMode.ANY:
        return this.children.some((c) => c.checkUser(aui));
      case CheckMode.ALL:
        return this.children.every((c) => c.checkUser(aui));
    }
  }
}

// Domain level ------------------------------------------------------------------------------------

export interface DomainAccessChecker {
  checkDomain: (udp: UserDomainPermissions) => boolean;
}

export class CompositeDomainAccessChecker implements DomainAccessChecker {
  constructor(
    private readonly childrenCheckMode: CheckMode = CheckMode.ALL,
    private readonly children: DomainAccessChecker[] = [],
  ) {}

  checkDomain(udp: UserDomainPermissions): boolean {
    switch (this.childrenCheckMode) {
      case CheckMode.ANY:
        return this.children.some((c) => c.checkDomain(udp));
      case CheckMode.ALL:
        return this.children.every((c) => c.checkDomain(udp));
    }
  }
}

export class DomainSitesAccessChecker implements DomainAccessChecker {
  constructor(
    private readonly sitesCheckMode: CheckMode,
    private readonly siteChecker: SiteAccessChecker,
  ) {}

  checkDomain(udp: UserDomainPermissions): boolean {
    switch (this.sitesCheckMode) {
      case CheckMode.ANY:
        return udp.domain.sites.some((s) => this.siteChecker.checkSite(s));
      case CheckMode.ALL:
        return udp.domain.sites.every((s) => this.siteChecker.checkSite(s));
    }
  }
}

// Site level --------------------------------------------------------------------------------------

export interface SiteAccessChecker {
  checkSite: (s: Site) => boolean;
}

export class CompositeSiteAccessChecker implements SiteAccessChecker {
  constructor(
    private readonly childrenCheckMode: CheckMode = CheckMode.ALL,
    private readonly children: SiteAccessChecker[] = [],
  ) {}

  checkSite(s: Site): boolean {
    switch (this.childrenCheckMode) {
      case CheckMode.ANY:
        return this.children.some((c) => c.checkSite(s));
      case CheckMode.ALL:
        return this.children.every((c) => c.checkSite(s));
    }
  }
}
