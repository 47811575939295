import type { MyCawanModule } from "@/shared/model/modules/MyCawanModule";

export class ModuleSubscription {
  readonly module: MyCawanModule;
  readonly startDate: Date;
  readonly endDate?: Date;
  constructor(module: MyCawanModule, startDate: Date, endDate?: Date) {
    this.module = module;
    this.startDate = startDate;
    this.endDate = endDate;
  }

  isActive(): boolean {
    const now = new Date().getTime();
    return (
      this.startDate.getTime() <= now &&
      (this.endDate === undefined || now <= this.endDate.getTime())
    );
  }
}
