import InputConfig from "@/layout/header/searchBar/model/search/input/InputConfig";
import ModuleSearchConfig from "@/layout/header/searchBar/model/search/ModuleSearchConfig";
import ColumnConfig from "@/layout/header/searchBar/model/search/result/ColumnConfig";
import ResultConfig from "@/layout/header/searchBar/model/search/result/ResultConfig";
import SearchConfig from "@/layout/header/searchBar/model/search/SearchConfig";

// -----------------------------------------------------------------------------
// Configurations des recherches achats/gestion
// -----------------------------------------------------------------------------

const MANAGEMENT_SEARCHES = new ModuleSearchConfig("management", [
  // Recherche bons de commandes
  new SearchConfig(
    "orders",
    "bdc",
    "GES_BDC_VISU",
    new InputConfig(true, 0),
    new ResultConfig([
      new ColumnConfig("domain"),
      new ColumnConfig("site"),
      new ColumnConfig("supplier"),
      new ColumnConfig("orderId", true),
      new ColumnConfig("date"),
    ]),
  ),
  // Recherche livraisons
  new SearchConfig(
    "deliveries",
    "bdl",
    "GES_BDL_VISU",
    new InputConfig(true, 0),
    new ResultConfig([
      new ColumnConfig("domain"),
      new ColumnConfig("site"),
      new ColumnConfig("supplier"),
      new ColumnConfig("deliveryId", true),
      new ColumnConfig("supplierId", true),
      new ColumnConfig("date"),
    ]),
  ),
  // Recherche fiche techniques
  new SearchConfig(
    "technicalSheets",
    "ft",
    "GES_FICHETECHNIQUE_VISU",
    new InputConfig(false, 3),
    new ResultConfig(
      [new ColumnConfig("domain"), new ColumnConfig("technicalSheet", true)],
      false,
    ),
  ),
  // Recherche fournisseurs
  new SearchConfig(
    "suppliers",
    "fournisseur",
    "GES_FOURNISSEUR_VISU",
    new InputConfig(false, 3),
    new ResultConfig(
      [new ColumnConfig("domain"), new ColumnConfig("supplier", true)],
      false,
    ),
  ),
  // Recherche produits (de gestion)
  new SearchConfig(
    "managementProducts",
    "prd",
    "GES_PRD_VISU",
    new InputConfig(false, 3),
    new ResultConfig(
      [
        new ColumnConfig("domain"),
        new ColumnConfig("name", true),
        new ColumnConfig("reference", true),
      ],
      false,
    ),
  ),
  // Recherche produits fournisseurs
  new SearchConfig(
    "supplierProducts",
    "prdfou",
    "GES_PRDFOU_VISU",
    new InputConfig(false, 3),
    new ResultConfig(
      [
        new ColumnConfig("domain"),
        new ColumnConfig("name", true),
        new ColumnConfig("reference", true),
      ],
      false,
    ),
  ),
]);

export default MANAGEMENT_SEARCHES;
