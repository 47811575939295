/******************************
 * GENERATED FILE, DO NOT EDIT *
 *******************************/ import type {
  ReactNode,
  SVGProps,
} from "react";
export default function IconCross(props: SVGProps<SVGSVGElement>): ReactNode {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16px"
      height="16px"
      fill="none"
      viewBox="0 0 10 10"
      color="currentColor"
      {...props}
    >
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit={10}
        strokeWidth={1.2}
        d="M9 1 1 9M1 1l8 8"
        fill="currentColor"
      />
    </svg>
  );
}
