import type Menu from "./Menu";

export default class MainMenuBreadCrumb {
  private readonly path: string[];
  private constructor(_path: string[]) {
    this.path = _path;
  }

  static create(): MainMenuBreadCrumb {
    return new MainMenuBreadCrumb([]);
  }

  isOnPath(index: number, menu: Menu): boolean {
    this.checkNonNegative(index);
    return index < this.path.length && this.path[index] === menu.key;
  }

  isLastActive(menu: Menu): boolean {
    if (this.path.length === 0) {
      return false;
    }
    return this.path[this.path.length - 1] === menu.key;
  }

  selectMenu(index: number, menu: Menu): MainMenuBreadCrumb {
    this.checkNonNegative(index);
    if (index > this.path.length) {
      throw new Error(
        `Cannot select index ${index}, current path has only ${this.path.length} elements`,
      );
    }
    const newPath = this.path.slice(0, index);
    newPath.push(menu.key);
    return new MainMenuBreadCrumb(newPath);
  }

  pop(): MainMenuBreadCrumb {
    return new MainMenuBreadCrumb(this.path.slice(0, -1));
  }

  length(): number {
    return this.path.length;
  }

  getMenuKeyAt(index: number): string {
    return this.path[index];
  }

  private checkNonNegative(index: number): void {
    if (index < 0) {
      throw new Error(`Illegal negative index: ${index}`);
    }
  }

  toString(): string {
    return `crumb[${this.path.join(", ")}]`;
  }
}
