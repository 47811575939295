import { MainHeaderDropDown } from "@/layout/header/menu/components/drop-down/MainHeaderDropDown";
import { MainHeaderDropDownItem } from "@/layout/header/menu/components/drop-down/MainHeaderDropDownItem";
import { MainHeaderMenuButton } from "@/layout/header/menu/components/button/MainHeaderMenuButton";
import type { UserFlags } from "@/shared/model/user/UserPermissions";
import type { ReactNode } from "react";
import { useTranslation } from "react-i18next";
import IconUniversity from "@/shared/components/generic/icons/IconUniversity.tsx";
import IconHandshake from "@/shared/components/generic/icons/IconHandshake.tsx";
import IconStopwatch from "@/shared/components/generic/icons/IconStopwatch.tsx";
import IconGear from "@/shared/components/generic/icons/IconGear.tsx";

export interface Properties {
  menuKey: string;
  activeMenuKey?: string;
  onClick: (menuKey: string) => void;
  flags?: UserFlags;
}

export default function AdministrationMenu(
  props: Properties,
): ReactNode | undefined {
  const { t } = useTranslation(["menus"]);
  if (props.flags === undefined) {
    return undefined;
  }
  if (!props.flags.isSalesPerson()) {
    return undefined;
  }
  return (
    <MainHeaderMenuButton
      icon={IconGear}
      tooltipText={t("menus:administration.title")}
      onClick={() => {
        props.onClick(props.menuKey);
      }}
      active={props.menuKey === props.activeMenuKey}
    >
      <MainHeaderDropDown title={t("menus:administration.title")}>
        {props.flags.isSupport() && (
          <MainHeaderDropDownItem
            address="/content/netrestoinstall/?task=install"
            label={t("menus:administration.createDomain")}
            icon={IconUniversity}
          />
        )}
        {props.flags.isSalesPerson() && (
          <MainHeaderDropDownItem
            address="/content/commercial/?task=overview"
            label={t("menus:administration.customerRelationship")}
            icon={IconHandshake}
          />
        )}
        {props.flags.isDevelopper() && (
          <MainHeaderDropDownItem
            address="/content/compteur/?task=manage"
            label={t("menus:administration.recomputeCounters")}
            icon={IconStopwatch}
          />
        )}
      </MainHeaderDropDown>
    </MainHeaderMenuButton>
  );
}
