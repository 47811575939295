/******************************
 * GENERATED FILE, DO NOT EDIT *
 *******************************/ import type {
  ReactNode,
  SVGProps,
} from "react";
export default function IconQuestionMarkBubble(
  props: SVGProps<SVGSVGElement>
): ReactNode {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16px"
      height="16px"
      fill="none"
      viewBox="0 0 30 30"
      color="currentColor"
      {...props}
    >
      <path
        d="m13.753 17.78-.52-2.82q.78-.2 1.4-.44a7 7 0 0 0 1.06-.54q.46-.3.76-.64t.44-.72q.16-.4.16-.84 0-.58-.32-1.04-.3-.46-.84-.72a2.8 2.8 0 0 0-1.22-.26q-.801 0-1.56.56-.74.54-1.28 1.54l-1.5-1.26q.86-1.32 2.04-2.04 1.2-.74 2.5-.74 1.26 0 2.26.52t1.58 1.42q.6.9.6 2.02 0 .66-.28 1.3a4.2 4.2 0 0 1-.76 1.18 5.8 5.8 0 0 1-1.18 1q-.66.44-1.46.72l-.34 1.8zm-.7 2.94q0-.66.38-1.08t1-.42q.66 0 1.02.4.36.38.36 1.1 0 .66-.38 1.08t-1 .42q-.66 0-1.02-.38-.36-.4-.36-1.12M30 15c0-8.284-6.716-15-15-15S0 6.716 0 15s6.716 15 15 15h12.809a1 1 0 0 0 .755-1.655l-2.058-2.372c-.336-.388-.321-.966.008-1.36A14.94 14.94 0 0 0 30 15"
        fill="currentColor"
      />
    </svg>
  );
}
