import { goToLoginWithTarget } from "@/shared/model/user/Logout";
import type { AxiosError, AxiosResponse } from "axios";

interface Alert {
  args: string[];
  code: string;
  message: string;
}

export function handleSuccess(response: AxiosResponse): AxiosResponse {
  redirectIfUnauthorized(response);
  return response;
}

export function handleError(error: AxiosError): Promise<never> {
  redirectIfUnauthorized(error.response);
  return Promise.reject(error);
}

function redirectIfUnauthorized(response: AxiosResponse | undefined): void {
  if (unauthorized(response) || notConnectedAlert(response)) {
    goToLoginWithTarget();
  }
}

function getAlerts(response: AxiosResponse | undefined): Alert[] {
  // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access -- json mapping
  return (response?.data?.head?.alerts ?? []) as Alert[];
}

function unauthorized(response: AxiosResponse | undefined): boolean {
  return response?.status === 401;
}

function notConnectedAlert(response: AxiosResponse | undefined): boolean {
  return getAlerts(response).some((a) =>
    a.code.includes("JSON_ERROR_USER_NOT_CONNECTED"),
  );
}
