import type { Item } from "@/shared/components/generic/items";
import type { ModuleSubscription } from "../modules/ModuleSubscription";
import type { SitePreferences } from "./SitePreferences";
import type { GenericPreferences } from "./GenericPreferences";
import type { MyCawanModule } from "@/shared/model/modules/MyCawanModule";

export class Domain {
  constructor(
    public readonly id: string,
    public readonly name: string,
    public readonly shortName: string,
    public readonly preferences: GenericPreferences,
    public readonly sites: Site[],
  ) {}

  hasModule(module: MyCawanModule): boolean {
    return this.sites.some((s) => s.hasModule(module));
  }

  findSite(siteId: string | undefined): Site | undefined {
    return this.sites.find((s) => s.id === siteId);
  }

  favoriteSites(): Site[] {
    return this.sites.filter((s) => s.isFavorite);
  }
}

export class Site {
  constructor(
    public readonly domainId: string,
    public readonly id: string,
    public readonly name: string,
    public readonly code: string,
    public readonly isFavorite: boolean,
    public readonly subscriptions: ModuleSubscription[],
    public readonly preferences: SitePreferences,
  ) {}

  hasModule(module: MyCawanModule): boolean {
    return this.subscriptions
      .filter((sub) => sub.module === module)
      .some((sub) => sub.isActive());
  }
}

export function domainsAsItems(domains: Domain[]): Item[] {
  return domains.map((d) => ({ key: d.id, label: d.name }));
}

export function sitesAsItems(
  domains: Domain[],
  domainId: string | undefined,
): Item[] {
  return (
    findDomain(domains, domainId)?.sites.map((s) => ({
      key: s.id,
      label: s.name,
    })) ?? []
  );
}

export function findDomain(
  domains: Domain[],
  domainId: string | undefined,
): Domain | undefined {
  return domains.find((d) => d.id === domainId);
}
