/******************************
 * GENERATED FILE, DO NOT EDIT *
 *******************************/ import type {
  ReactNode,
  SVGProps,
} from "react";
export default function IconInvestigator(
  props: SVGProps<SVGSVGElement>
): ReactNode {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16px"
      height="16px"
      viewBox="0 0 16 16"
      color="currentColor"
      {...props}
    >
      <path
        d="m6.77 14.77.922-4.308L6.77 9.23l-1.23-.616Zm2.46 0 1.232-6.155-1.231.616-.923 1.23Zm1.54-9.712q-.02-.039-.04-.058-.095-.077-.922-.077-.673 0-1.606.183-.067.019-.202.019t-.202-.02q-.933-.182-1.606-.182-.827 0-.923.077-.019.02-.038.058.019.173.038.26.02.028.072.062.053.033.072.1.02.04.073.198.052.159.067.197.014.038.072.163t.082.164l.086.135q.063.096.116.13.053.033.134.09.082.058.169.078.086.019.197.038.11.02.235.02.346 0 .568-.12.22-.121.312-.29.091-.168.14-.331.048-.164.11-.284.063-.12.168-.12h.116q.105 0 .168.12.062.12.11.284.049.163.14.332.091.168.312.288.222.12.568.12.125 0 .235-.019.11-.02.197-.038.087-.02.169-.077.081-.058.134-.092t.116-.13l.086-.134q.024-.039.082-.164t.072-.163q.015-.038.067-.197t.073-.197q.019-.068.072-.101.053-.034.072-.063.019-.086.038-.26m4 8.452q0 1.163-.703 1.827-.702.663-1.865.663H3.798q-1.163 0-1.865-.663-.702-.664-.702-1.827 0-.587.043-1.135t.183-1.207q.14-.658.36-1.187.221-.53.61-.995.39-.467.9-.717l-.865-2.115h2.057q-.211-.616-.211-1.23 0-.116.019-.309-1.865-.384-1.865-.923 0-.548 2.019-.952.163-.596.495-1.288T5.654.356Q5.962 0 6.384 0q.29 0 .808.298.52.298.808.298t.808-.298T9.615 0q.423 0 .731.356.346.404.678 1.096t.495 1.288q2.02.404 2.02.952 0 .539-1.866.923.067.78-.192 1.539h2.057l-.788 2.163q.606.318 1.034.928t.63 1.38q.201.77.278 1.457t.077 1.428"
        fill="currentColor"
      />
    </svg>
  );
}
