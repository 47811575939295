/******************************
 * GENERATED FILE, DO NOT EDIT *
 *******************************/ import type {
  ReactNode,
  SVGProps,
} from "react";
export default function IconPhoneDisc(
  props: SVGProps<SVGSVGElement>
): ReactNode {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16px"
      height="16px"
      fill="none"
      viewBox="0 0 14 14"
      color="currentColor"
      {...props}
    >
      <path
        fill="currentColor"
        d="M7 0C3.14 0 0 3.14 0 7s3.14 7 7 7 7-3.14 7-7-3.14-7-7-7m3.305 9.676-.305.35a1.64 1.64 0 0 1-1.167.474c-2.166 0-5.333-3-5.333-5.333 0-.422.152-.846.474-1.167l.35-.305c.26-.26.683-.26.943 0l.583.76c.26.26.26.682 0 .943l-.496.623c.525 1.31 1.417 2.164 2.625 2.625l.623-.496c.26-.26.683-.26.943 0l.76.583c.26.26.26.683 0 .943"
      />
    </svg>
  );
}
