import { GlobalSearchContext } from "@/layout/header/searchBar/GlobalSearchContext";
import type SearchConfig from "@/layout/header/searchBar/model/search/SearchConfig";
import { SearchI18nKeyComposer } from "@/layout/header/searchBar/model/search/SearchesI18N";
import { useContext, type ReactNode } from "react";
import { Trans, useTranslation } from "react-i18next";
import "./ModuleSearchDataType.scss";
import IconMagnifier from "@/shared/components/generic/icons/IconMagnifier.tsx";

interface Properties {
  searchConfig: SearchConfig;
  userInput: string;
}

export function ModuleSearchDataType(props: Properties): ReactNode {
  const { i18n } = useTranslation("globalSearch");
  const i18nKeys = new SearchI18nKeyComposer(i18n.exists);
  const input = formatUserInput();
  const { setSearchConfig } = useContext(GlobalSearchContext);
  return (
    <li
      className="search-item"
      onClick={() => {
        setSearchConfig(props.searchConfig);
      }}
    >
      <IconMagnifier />
      <div>
        <Trans
          i18nKey={i18nKeys.prompt(props.searchConfig)}
          values={{ input }}
        />
      </div>
    </li>
  );

  function formatUserInput(): string {
    if (props.searchConfig.inputConfig.numericInput) {
      return isNaN(Number(props.userInput)) ? "???" : props.userInput;
    }
    return props.userInput;
  }
}
