import { clearBackendCache } from "@/shared/model/user/UserJsCache";

export default async function logout(): Promise<void> {
  await fetch("/rest/logout", { method: "DELETE" })
    .then(clearBackendCache)
    .then(clearCookie)
    .then(goToRoot);
}

function clearCookie(): void {
  const cookies = document.cookie;
  const cookie = cookies.split(";").find((s) => s.startsWith("JSESSIONID="));
  if (cookie !== undefined) {
    document.cookie = "JSESSIONID=;expires=Thu, 01 Jan 1970 00:00:01 GMT";
  }
}

function goToRoot(): void {
  window.location.href = "/";
}
export function goToLoginWithTarget(): void {
  window.location.href = `/?loginRequired=true&target=${window.location.pathname}`;
}
