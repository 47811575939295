/******************************
 * GENERATED FILE, DO NOT EDIT *
 *******************************/ import type {
  ReactNode,
  SVGProps,
} from "react";
export default function IconEnvelopeFill(
  props: SVGProps<SVGSVGElement>
): ReactNode {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16px"
      height="16px"
      fill="none"
      viewBox="0 0 14 12"
      color="currentColor"
      {...props}
    >
      <path
        fill="currentColor"
        d="M8.17 7.578a2.1 2.1 0 0 1-2.34 0L.093 3.753 0 3.687v6.267c0 .718.583 1.289 1.289 1.289H12.71c.719 0 1.289-.583 1.289-1.29V3.688q-.046.034-.093.066z"
      />
      <path
        fill="currentColor"
        d="m.548 3.07 5.737 3.825a1.29 1.29 0 0 0 1.43 0l5.737-3.825c.343-.228.548-.611.548-1.024A1.29 1.29 0 0 0 12.712.757H1.288A1.29 1.29 0 0 0 0 2.047c0 .412.205.795.548 1.023"
      />
    </svg>
  );
}
