import {
  type PropsWithChildren,
  type ReactNode,
  useEffect,
  useRef,
} from "react";
import "./MainHeaderDropDown.scss";

export interface Properties {
  title: string;
}

export function MainHeaderDropDown(
  props: PropsWithChildren<Properties>,
): ReactNode {
  const rootRef = useRef<HTMLDivElement>(null);
  useEffect(() => {
    if (rootRef.current !== null) {
      const rect: DOMRect = rootRef.current.getBoundingClientRect();
      const docWidth = document.documentElement.clientWidth;
      if (rect.right > docWidth) {
        rootRef.current.style.right = "0";
      }
    }
  }, []);

  return (
    <div ref={rootRef} className="main-header-drop-down">
      <h1 className="title">{props.title}</h1>
      <ul>{props.children}</ul>
    </div>
  );
}
