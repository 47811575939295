import { getPage } from "@/shared/model/pages-management/AllPages";
import type { Page } from "@/shared/model/pages-management/ApplicationPages";
import { useNavigate } from "react-router-dom";

export default function usePageNavigation(): {
  jumpTo: (pageKey: string) => void;
} {
  const navigate = useNavigate();
  return {
    jumpTo: (pageKey: string): void => {
      const page: Page = getPage(pageKey);
      if (page.isReact()) {
        void navigate(page.buildUrl());
      } else {
        window.location.href = page.buildUrl();
      }
    },
  };
}
