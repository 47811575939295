import type { MyCawanModule } from "@/shared/model/modules/MyCawanModule";
import type { Domain } from "@/shared/model/user/UserDomains";
import * as Arrays from "@/shared/utils/Arrays";

export class UserPermissions {
  constructor(
    readonly permissionsByDomainId: Map<string, UserDomainPermissions>,
    readonly flags: UserFlags,
  ) {}

  isEmpty(): boolean {
    return this.permissionsByDomainId.size === 0;
  }

  domainsPermissions(): UserDomainPermissions[] {
    return Array.from(this.permissionsByDomainId.values());
  }

  ofDomainId(domainId: string): UserDomainPermissions | undefined {
    return this.permissionsByDomainId.get(domainId);
  }

  domain(domainId: string): Domain | undefined {
    return this.ofDomainId(domainId)?.domain;
  }

  domains(): Domain[] {
    return this.filteredDomains(() => true);
  }

  adminOnAnyDomain(): boolean {
    return this.domainsWhereAdmin.length > 0;
  }

  domainsWhereAdmin(): Domain[] {
    return this.filteredDomains((udp) => udp.isAdmin);
  }

  permissionsOfDomainsWithModule(
    module: MyCawanModule,
  ): UserDomainPermissions[] {
    return this.filteredDomainsPermissions((udp) =>
      udp.domain.hasModule(module),
    );
  }

  hasModule(module: MyCawanModule): boolean {
    return this.domains().some((d) => d.hasModule(module));
  }

  domainsWithPermission(permission: string): Domain[] {
    return this.domainsWithAnyPermission([permission]);
  }

  hasPermissionOnAnyDomain(permission: string): boolean {
    return this.domainsWithPermission(permission).length > 0;
  }

  domainsWithEveryPermissions(permissions: string[]): Domain[] {
    return this.filteredDomains((udp) => udp.hasEveryPermissions(permissions));
  }

  domainsWithAnyPermission(permissions: string[]): Domain[] {
    return this.filteredDomains((udp) => udp.hasAnyPermission(permissions));
  }

  private filteredDomainsPermissions(
    filter: (udp: UserDomainPermissions) => boolean,
  ): UserDomainPermissions[] {
    return Array.from(this.permissionsByDomainId.values()).filter(filter);
  }

  private filteredDomains(
    filter: (udp: UserDomainPermissions) => boolean,
  ): Domain[] {
    return this.filteredDomainsPermissions(filter)
      .map((udp) => udp.domain)
      .sort(Arrays.compareFn((d) => d.name.trim()));
  }
}

/**
 * Flags are implied as follows:
 * developper => support => commercial => beta_tester
 */
export class UserFlags {
  private constructor(
    private readonly developper: boolean,
    private readonly support: boolean,
    private readonly salesPerson: boolean,
    private readonly betaTester: boolean,
  ) {}

  static createDevelopper(): UserFlags {
    return new UserFlags(true, false, false, false);
  }

  static createSupport(): UserFlags {
    return new UserFlags(false, true, false, false);
  }

  static createSalesPerson(): UserFlags {
    return new UserFlags(false, false, true, false);
  }

  static createBetaTester(): UserFlags {
    return new UserFlags(false, false, false, true);
  }

  static createNoFlags(): UserFlags {
    return new UserFlags(false, false, false, false);
  }

  isDevelopper(): boolean {
    return this.developper;
  }

  isSupport(): boolean {
    return this.isDevelopper() || this.support;
  }

  isSalesPerson(): boolean {
    return this.isSupport() || this.salesPerson;
  }

  isBetaTester(): boolean {
    return this.isSalesPerson() || this.betaTester;
  }
}

export class UserDomainPermissions {
  constructor(
    public readonly domain: Domain,
    public readonly isAdmin: boolean,
    public readonly permissions: string[],
  ) {}

  canAccessSiteId(siteId: string): boolean {
    return this.domain.sites.some((s) => s.id === siteId);
  }

  hasPermission(permission: string): boolean {
    return this.isAdmin || this.permissions.includes(permission);
  }

  hasAnyPermission(permissions: string[]): boolean {
    return permissions.some((p) => this.permissions.includes(p));
  }

  hasEveryPermissions(permissions: string[]): boolean {
    return permissions.every((p) => this.permissions.includes(p));
  }
}
