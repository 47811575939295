import { useContext, type ReactNode } from "react";
import { AppUserInfoContext } from "@/shared/model/user/AppUserInfoContext";
import "./SearchPanel.scss";
import classNames from "classnames";
import { GlobalSearchContext } from "@/layout/header/searchBar/GlobalSearchContext";
import { PagesListLazy } from "@/layout/header/searchBar/search-panel/pages-list/PagesList";
import { GlobalSearch } from "@/layout/header/searchBar/search-panel/global-search/GlobalSearch";
import HUMAN_RESOURCES_SEARCHES from "@/layout/header/searchBar/model/search/modules/HumanResources";
import MANAGEMENT_SEARCHES from "@/layout/header/searchBar/model/search/modules/Management";
import HACCP_SEARCHES from "@/layout/header/searchBar/model/search/modules/Haccp";

export interface Properties {
  onLeave: () => void;
  visible: boolean;
}

export default function SearchPanel(props: Properties): ReactNode {
  const appUserInfo = useContext(AppUserInfoContext);
  const { userInput } = useContext(GlobalSearchContext);
  const searchableModules = [
    HUMAN_RESOURCES_SEARCHES,
    MANAGEMENT_SEARCHES,
    HACCP_SEARCHES,
  ].filter((m) => m.userCanAccess(appUserInfo));

  const hide = userInput === "" || !props.visible;

  return (
    <div
      className={classNames("search-panel", hide && "hidden")}
      onMouseLeave={props.onLeave}
    >
      <div>
        <PagesListLazy />
        {searchableModules.length > 0 && (
          <GlobalSearch modules={searchableModules} />
        )}
      </div>
    </div>
  );
}
