import IconPhoneDisc from "@/shared/components/generic/icons/IconPhoneDisc.tsx";
import IconEnvelopeFill from "@/shared/components/generic/icons/IconEnvelopeFill.tsx";
import type { ReactNode } from "react";
import "./SupportTooltip.scss";
import "@/shared/style/design_system/Fonts.scss";

export function SupportTooltip(): ReactNode {
  return (
    <div className="support-tooltip body small">
      <div className="support-title">Support</div>
      <div className="info-row">
        <IconPhoneDisc />
        <span>01 53 23 08 40</span>
      </div>
      <div className="info-row">
        <IconEnvelopeFill />
        <span>support@mycawan.com</span>
      </div>
    </div>
  );
}
