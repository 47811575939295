import type SearchConfig from "@/layout/header/searchBar/model/search/SearchConfig";
import SearchPanel from "@/layout/header/searchBar/search-panel/SearchPanel";
import { useState, type ReactNode } from "react";
import SearchBarInput from "./input/SearchBarInput";
import { SearchResultPop } from "./result-popup/SearchResultPopup";
import {
  GlobalSearchContext,
  GlobalSearchContextValue,
} from "./GlobalSearchContext";

export function SearchBar(): ReactNode {
  const [userInput, setUserInput] = useState<string>("");
  const [searchConfig, setSearchConfig] = useState<SearchConfig>();
  const [panelVisible, setPanelVisible] = useState(true);

  const value = new GlobalSearchContextValue(
    userInput,
    setUserInput,
    searchConfig,
    setSearchConfig,
  );

  function handleUserInputChange(s: string): void {
    setUserInput(s);
    setSearchConfig(undefined);
    showPanel();
  }

  function showPanel(): void {
    setPanelVisible(true);
  }

  function hidePanel(): void {
    setPanelVisible(false);
  }

  function handleResultClosed(): void {
    setSearchConfig(undefined);
  }

  const validSearchConfig =
    searchConfig?.inputConfig.validInput(userInput) === true ?
      searchConfig
    : undefined;

  return (
    <GlobalSearchContext.Provider value={value}>
      <SearchBarInput onChange={handleUserInputChange} onClick={showPanel} />
      <SearchPanel onLeave={hidePanel} visible={panelVisible} />
      {validSearchConfig !== undefined && (
        <SearchResultPop
          onResultClosed={handleResultClosed}
          userInput={userInput}
          searchConfig={validSearchConfig}
        />
      )}
    </GlobalSearchContext.Provider>
  );
}
