import type SearchConfig from "@/layout/header/searchBar/model/search/SearchConfig";
import { createContext } from "react";

export class GlobalSearchContextValue {
  constructor(
    readonly userInput: string,
    readonly setUserInput: (s: string) => void,
    readonly searchConfig: SearchConfig | undefined,
    readonly setSearchConfig: (c: SearchConfig) => void,
  ) {}
}

export const GlobalSearchContext = createContext(
  new GlobalSearchContextValue(
    "",
    _missingSetter("userInput"),
    undefined,
    _missingSetter("searchConfig"),
  ),
);

function _missingSetter(fieldName: string): (t: unknown) => void {
  return (t: unknown) => {
    console.warn(
      `No setter defined to set value ${String(t)} for field '${fieldName}'`,
    );
  };
}
