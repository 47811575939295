import type ModuleSearchConfig from "@/layout/header/searchBar/model/search/ModuleSearchConfig";
import type ColumnConfig from "@/layout/header/searchBar/model/search/result/ColumnConfig";
import type SearchConfig from "@/layout/header/searchBar/model/search/SearchConfig";

export class SearchI18nKeyComposer {
  constructor(readonly keyExists: (k: string) => boolean) {}

  public moduleTitle(module: ModuleSearchConfig): string {
    return moduleKey(module) + ".title";
  }

  public prompt(search: SearchConfig): string {
    return searchKey(search) + ".prompt";
  }

  public resultTitle(search: SearchConfig): string {
    return resultTitle(search);
  }

  public columnTitle(col: ColumnConfig): string {
    const searchSpecificKey = columnKey(col);
    if (this.keyExists(searchSpecificKey)) {
      return searchSpecificKey;
    }
    const moduleCommonKey = moduleCommonColumnKey(col);
    if (this.keyExists(moduleCommonKey)) {
      return moduleCommonKey;
    }
    const commonKey = modulesCommonKey(col);
    if (this.keyExists(commonKey)) {
      return commonKey;
    }
    console.error("Title not found for column", col);
    return col.i18nKey;
  }

  public inputIsNotANumber(): string {
    return inputError("notANumber");
  }

  public inputTooShort(): string {
    return inputError("inputTooShort");
  }
}

function resultTitle(search: SearchConfig): string {
  return resultKey(search) + ".title";
}

function columnKey(col: ColumnConfig): string {
  return resultKey(col.parent?.parent) + ".columns." + col.i18nKey;
}

function moduleCommonColumnKey(col: ColumnConfig): string {
  return (
    moduleKey(col.parent?.parent?.parent) + ".commonColumns." + col.i18nKey
  );
}

function modulesCommonKey(col: ColumnConfig): string {
  return ns("searchModules.commonColumns." + col.i18nKey);
}

function searchKey(search: SearchConfig | undefined): string {
  if (search === undefined) {
    throw new Error("Undefined search");
  }
  return moduleKey(search.parent) + ".searches." + search.i18nKey;
}

function resultKey(search: SearchConfig | undefined): string {
  if (search === undefined) {
    throw new Error("Undefined search");
  }
  return searchKey(search) + ".result";
}

function moduleKey(module?: ModuleSearchConfig): string {
  if (module === undefined) {
    throw new Error("Undefined module");
  }
  return ns(`searchModules.${module.i18nKey}`);
}

function inputError(key: string): string {
  return ns(`inputErrors.${key}`);
}

function ns(key: string): string {
  return `globalSearch:${key}`;
}
