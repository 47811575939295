/******************************
* GENERATED FILE, DO NOT EDIT *
*******************************/
export type ColorName = "ocean"|"oceanHover"|"oceanDisabled"|"sable"|"sableHover"|"sableDisabled"|"lagon"|"lagonHover"|"lagonDisabled"|"eauDouce"|"eauDouceHover"|"eauDouceDisabled"|"algue"|"algueHover"|"algueDisabled"|"ecume"|"ecumeHover"|"ecumeDisabled"|"cloud"|"cloudHover"|"cloudDisabled"|"darkCloud"|"darkCloudHover"|"darkCloudDisabled"|"info"|"infoHover"|"infoDisabled"|"validated"|"validatedHover"|"validatedDisabled"|"alert"|"alertHover"|"alertDisabled"|"error"|"errorHover"|"errorDisabled"|"accueilSecondaryActive"|"accueilSecondaryHover"|"accueilTertiaryActive"|"accueilTertiaryHover"|"humanResourcesSecondaryActive"|"humanResourcesSecondaryHover"|"humanResourcesTertiaryActive"|"humanResourcesTertiaryHover"|"materialsManagementSecondaryActive"|"materialsManagementSecondaryHover"|"materialsManagementTertiaryActive"|"materialsManagementTertiaryHover"|"haccpSecondaryActive"|"haccpSecondaryHover"|"haccpTertiaryActive"|"haccpTertiaryHover"|"accountingSecondaryActive"|"accountingSecondaryHover"|"accountingTertiaryActive"|"accountingTertiaryHover"|"analysisSecondaryActive"|"analysisSecondaryHover"|"analysisTertiaryActive"|"analysisTertiaryHover"|"cashRegistersSecondaryActive"|"cashRegistersSecondaryHover"|"importExportSecondaryActive"|"importExportSecondaryHover"|"importExportTertiaryActive"|"importExportTertiaryHover";
export const colors: Record<ColorName, string> = {
  ocean: "#150e56",
  oceanHover: "#0d0935",
  oceanDisabled: "#7d7a9d",
  sable: "#fae496",
  sableHover: "#e7d081",
  sableDisabled: "#fff3c8",
  lagon: "#4ebfb4",
  lagonHover: "#3e9990",
  lagonDisabled: "#90d7d0",
  eauDouce: "#cadcf6",
  eauDouceHover: "#b1c6e5",
  eauDouceDisabled: "#e8f0f9",
  algue: "#d0e3db",
  algueHover: "#b2d1c4",
  algueDisabled: "#e9f2ee",
  ecume: "#fff",
  ecumeHover: "#f4f6f6",
  ecumeDisabled: "#fcfcfc",
  cloud: "#f0f2f3",
  cloudHover: "#e7e7e7",
  cloudDisabled: "#f7f8f9",
  darkCloud: "#d1d9e3",
  darkCloudHover: "#bac2cc",
  darkCloudDisabled: "#e5eaf0",
  info: "#2c6ac6",
  infoHover: "#2054a3",
  infoDisabled: "#7fabee",
  validated: "#88c941",
  validatedHover: "#75ae36",
  validatedDisabled: "#bedaa1",
  alert: "#f2a93c",
  alertHover: "#dd8500",
  alertDisabled: "#ffc670",
  error: "#dd3434",
  errorHover: "#be0b0b",
  errorDisabled: "#eb6969",
  accueilSecondaryActive: "#90d7d0",
  accueilSecondaryHover: "#4ebfb4",
  accueilTertiaryActive: "#4ebfb4",
  accueilTertiaryHover: "#3e988f",
  humanResourcesSecondaryActive: "#dceed8",
  humanResourcesSecondaryHover: "#b2d5aa",
  humanResourcesTertiaryActive: "#b2d5aa",
  humanResourcesTertiaryHover: "#96bf8d",
  materialsManagementSecondaryActive: "#f6e0ff",
  materialsManagementSecondaryHover: "#e9ccf5",
  materialsManagementTertiaryActive: "#e9ccf5",
  materialsManagementTertiaryHover: "#dcadf0",
  haccpSecondaryActive: "#e8f2fd",
  haccpSecondaryHover: "#ccdaea",
  haccpTertiaryActive: "#ccdaea",
  haccpTertiaryHover: "#a7c4e2",
  accountingSecondaryActive: "#ddb8a6",
  accountingSecondaryHover: "#c69a86",
  accountingTertiaryActive: "#c69a86",
  accountingTertiaryHover: "#b5785e",
  analysisSecondaryActive: "#fff6d6",
  analysisSecondaryHover: "#ffedad",
  analysisTertiaryActive: "#ffedad",
  analysisTertiaryHover: "#f4dd8b",
  cashRegistersSecondaryActive: "#f6d7d1",
  cashRegistersSecondaryHover: "#fcae9d",
  importExportSecondaryActive: "#dad6ff",
  importExportSecondaryHover: "#bcb7f5",
  importExportTertiaryActive: "#bcb7f5",
  importExportTertiaryHover: "#9992e7",
}