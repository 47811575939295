import { isValid, parse } from "date-fns";

export function parseIsoDate(s: string): Date {
  return parseOrThrow(s, "yyyy-MM-dd");
}

export function parseIsoDateTime(s: string): Date {
  return parseOrThrow(s, "yyyy-MM-dd'T'HH:mm:ss");
}

function parseOrThrow(s: string, pattern: string): Date {
  const res = parse(s, `${pattern}XXX`, 0);
  if (!isValid(res)) {
    throw new Error(`Failed to parse string: '${s}'`);
  }
  return res;
}

export enum DateStyle {
  YEAR = "YEAR",
  YEAR_MONTH = "YEAR_MONTH",
  YEAR_MONTH_WORD = "YEAR_MONTH_WORD",
  YEAR_MONTH_WORD_SHORT = "YEAR_MONTH_WORD_SHORT",
  DATE = "DATE",
  DATE_WEEKDAY = "DATE_WEEKDAY",
  DATE_WEEKDAY_SHORT = "DATE_WEEKDAY_SHORT",
  DATETIME = "DATETIME",
  TIME = "TIME",
}

export function formatDate(
  date: Date,
  language: string,
  style: DateStyle,
  timeZone?: string,
): string {
  const options = styleToOptions(style);
  if (timeZone !== undefined) {
    options.timeZone = timeZone;
  }
  return new Intl.DateTimeFormat(language, options).format(date);
}

function styleToOptions(style: DateStyle): Intl.DateTimeFormatOptions {
  const yearNum = "numeric"; // "2-digit";
  const monthNum = "numeric"; // "2-digit";
  const dayNum = "numeric"; // "2-digit";
  switch (style) {
    case DateStyle.YEAR:
      return { year: yearNum };
    case DateStyle.YEAR_MONTH:
      return { year: yearNum, month: monthNum };
    case DateStyle.YEAR_MONTH_WORD:
      return { year: yearNum, month: "long" };
    case DateStyle.YEAR_MONTH_WORD_SHORT:
      return { year: yearNum, month: "short" };
    case DateStyle.DATE:
      return {
        year: yearNum,
        month: monthNum,
        day: dayNum,
      };
    case DateStyle.DATE_WEEKDAY:
      return {
        year: yearNum,
        month: monthNum,
        day: dayNum,
        weekday: "long",
      };
    case DateStyle.DATE_WEEKDAY_SHORT:
      return {
        year: yearNum,
        month: monthNum,
        day: dayNum,
        weekday: "short",
      };
    case DateStyle.DATETIME:
      return {
        year: yearNum,
        month: monthNum,
        day: dayNum,
        hour: "numeric",
        minute: "numeric",
      };
    case DateStyle.TIME:
      return {
        hour: "2-digit",
        minute: "2-digit",
      };
  }
}
