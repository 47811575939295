import { useContext, type ReactNode } from "react";
import { useTranslation } from "react-i18next";
import "./SearchBarInput.scss";
import { GlobalSearchContext } from "../GlobalSearchContext";
import IconCross from "@/shared/components/generic/icons/IconCross.tsx";
import IconMagnifier2 from "@/shared/components/generic/icons/IconMagnifier2.tsx";

export interface Properties {
  onChange: (input: string) => void;
  onClick: () => void;
}

export default function SearchBarInput(props: Properties): ReactNode {
  const { userInput, setUserInput } = useContext(GlobalSearchContext);
  const { t } = useTranslation();

  function updateAndNotify(newValue: string): void {
    setUserInput(newValue);
    props.onChange(newValue);
  }

  const grey = "#adaacf";
  return (
    <div className="search-bar-input">
      <IconMagnifier2 color={grey} />
      <input
        className="search-Input"
        name="searchText"
        placeholder={`${t("common:search")}...`}
        onClick={props.onClick}
        onChange={(e) => {
          updateAndNotify(e.target.value);
        }}
        value={userInput}
      />
      <IconCross
        className="clickable"
        width="10px"
        height="10px"
        color={grey}
        onClick={() => {
          updateAndNotify("");
        }}
      />
    </div>
  );
}
