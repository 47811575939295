import Menu from "@/layout/sticky-column/main-menu/model/Menu";
import { ALL_PAGES } from "@/shared/model/pages-management/AllPages";
import type { Page } from "@/shared/model/pages-management/ApplicationPages";
import type { AppUserInfo } from "@/shared/model/user/AppUserInfo";
import mainMenuJson from "./main_menu.json";
import { MainMenuFilter } from "./MainMenuFilter";

export default function buildMenuFor(aui: AppUserInfo): Menu | undefined {
  const menuConfig = mainMenuJson as JsonMenu[];
  const fullMenu = loadFullMenu(
    {
      key: "root",
      children: menuConfig,
    },
    ALL_PAGES,
  );
  return new MainMenuFilter(ALL_PAGES).filteredForUser(aui, fullMenu);
}

function loadFullMenu(menuNode: JsonMenu, pages: Map<string, Page>): Menu {
  const pageKey = menuNode.navigate_to_page;
  if (pageKey !== undefined && pages.get(pageKey) === undefined) {
    throw new Error(
      `Menu item ${menuNode.key} refers to missing page '${pageKey}'`,
    );
  }
  return new Menu(
    menuNode.key,
    (menuNode.children ?? []).map((c) => loadFullMenu(c, pages)),
    menuNode.navigate_to_page,
  );
}

interface JsonMenu {
  key: string;
  navigate_to_page?: string;
  children?: JsonMenu[];
}
