import type { ReactNode } from "react";
import "./Logo.scss";
import IconLogoMycawan from "@/shared/components/generic/icons/IconLogoMycawan.tsx";

export default function Logo(): ReactNode {
  const version = import.meta.env.VITE_MYCAWAN_VERSION ?? "0.0.0";
  return (
    <a href="/content/currentuser/?task=viewportal" title={version}>
      <IconLogoMycawan className={"logo"} />
    </a>
  );
}
