import "@/shared/style/design_system/Fonts.scss";
import type { ReactNode } from "react";
import { lazy, Suspense } from "react";
import { useTranslation } from "react-i18next";
import "./PagesList.scss";

const PagesList = lazy(() => import("./links/PagesLinksList"));

export function PagesListLazy(): ReactNode {
  const { t } = useTranslation("globalSearch");
  return (
    <div className="body pages-list">
      <h1>{t("searchPages")}</h1>
      <Suspense fallback="...">
        <PagesList />
      </Suspense>
    </div>
  );
}
