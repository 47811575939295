export interface ResultRow {
  matchLevel: number;
  values: string[];
  url: string | undefined;
}

export function jsonToResultRow(json: object): ResultRow {
  const obj = json as ResultRowJson;
  return {
    matchLevel: obj.matchLevel,
    values: obj.title.map((t) => t ?? ""),
    url: obj.url,
  };
}

interface ResultRowJson {
  matchLevel: number;
  title: (string | null)[];
  url: string | undefined;
}
