import classNames from "classnames";
import type { ReactNode } from "react";
import { useTranslation } from "react-i18next";
import "./MainMenu.scss";
import type MainMenuBreadCrumb from "./model/MainMenuBreadCrumb";
import MainMenuLevel2 from "./MainMenuLevel2";
import SubMenu from "./SubMenu";
import type Menu from "./model/Menu";
import MainMenuItem from "./MainMenuItem";

import { menuIconClass } from "./styling-helpers/MainMenuIcons";
import usePageNavigation from "@/shared/model/pages-management/usePageNavigation";

export interface Properties {
  menuItem: Menu;
  userCrumb: MainMenuBreadCrumb;
  onUserChangedCrumb: (bc: MainMenuBreadCrumb) => void;
}

const MAX_HEIGHTS = new Map([
  ["people_management", "people-management"],
  ["materials_management", "materials-management"],
  ["haccp", "haccp"],
]);

export default function MainMenuLevel1(props: Properties): ReactNode {
  const pageNav = usePageNavigation();
  const { t } = useTranslation(["mainMenu"]);
  const menuItem = props.menuItem;
  const classes = classNames(
    "main-menu-level-1",
    menuUsesLevel2() || "clickable",
    isActive() || MAX_HEIGHTS.get(props.menuItem.key),
    isActive() && "active",
  );
  return (
    <div className={classes} onClick={handleClick}>
      {isActive() && !menuUsesLevel2() && skippedLevel2Active() && (
        <SubMenu
          depth={2}
          subLevel={1}
          parentMenu={menuItem.children[0]}
          userCrumb={props.userCrumb}
          onUserChangedCrumb={props.onUserChangedCrumb}
        >
          {props.menuItem.children[0].children.map((m) => (
            <MainMenuItem
              key={m.key}
              depth={2}
              menuItem={m}
              userCrumb={props.userCrumb}
              onUserChangedCrumb={props.onUserChangedCrumb}
            />
          ))}
        </SubMenu>
      )}
      <div className="level-1-button">
        <div className="icon">
          <div className={menuIconClass(menuItem)}></div>
        </div>
        <div className="label">{t(`mainMenu:${menuItem.key}`)}</div>
      </div>
      {menuUsesLevel2() && renderAsLevel2(menuItem.children)}
    </div>
  );

  function isActive(): boolean {
    return props.userCrumb.isOnPath(0, menuItem);
  }

  function skippedLevel2Active(): boolean {
    return props.userCrumb.length() > 1;
  }

  function renderAsLevel2(children: Menu[]): ReactNode {
    // TODO: Make this function a component ?
    if (children.length === 0) {
      return undefined;
    }
    return (
      <>
        <div className="arrow-down">
          <div />
        </div>
        {children.map((m) => (
          <MainMenuLevel2
            key={m.key}
            menuItem={m}
            userCrumb={props.userCrumb}
            onUserChangedCrumb={props.onUserChangedCrumb}
          />
        ))}
        <div className="level-2-spacer"></div>
      </>
    );
  }

  function handleClick(): void {
    if (menuUsesLevel2()) {
      // Level 2 is managed with an hover event
      return;
    }
    const menuItem = props.menuItem;
    if (menuItem.noChildren() && menuItem.hasTargetPage()) {
      pageNav.jumpTo(menuItem.getTargetPage());
    } else if (isActive() && !menuUsesLevel2() && menuItem.hasTargetPage()) {
      pageNav.jumpTo(menuItem.getTargetPage());
    } else {
      props.onUserChangedCrumb(
        props.userCrumb
          .selectMenu(0, menuItem)
          .selectMenu(1, menuItem.children[0]),
      );
    }
  }

  function menuUsesLevel2(): boolean {
    return MAX_HEIGHTS.has(props.menuItem.key);
  }
}
