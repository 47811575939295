import type InputConfig from "@/layout/header/searchBar/model/search/input/InputConfig";
import type ModuleSearchConfig from "@/layout/header/searchBar/model/search/ModuleSearchConfig";
import type ResultConfig from "@/layout/header/searchBar/model/search/result/ResultConfig";
import type { AppUserInfo } from "@/shared/model/user/AppUserInfo";

export default class SearchConfig {
  constructor(
    public readonly i18nKey: string,
    public readonly searchType: string,
    public readonly requiredPermission: string,
    public readonly inputConfig: InputConfig,
    public readonly resultConfig: ResultConfig,
    public parent?: ModuleSearchConfig,
  ) {
    this.resultConfig.parent = this;
  }

  public userCanAccess(aui: AppUserInfo): boolean {
    return (
      aui.permissions?.hasPermissionOnAnyDomain(this.requiredPermission) ??
      false
    );
  }
}
