/******************************
 * GENERATED FILE, DO NOT EDIT *
 *******************************/ import type {
  ReactNode,
  SVGProps,
} from "react";
export default function IconStopwatch(
  props: SVGProps<SVGSVGElement>
): ReactNode {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16px"
      height="16px"
      viewBox="0 0 12 12"
      color="currentColor"
      {...props}
    >
      <path
        d="M81.043 35.207 87.5 28.75c.832-.832.832-2.082 0-2.918-.832-.832-2.082-.832-2.918 0l-6.25 6.25c-5.414-5.414-12.289-9.164-20-10.832v-5.418h1.457a4.18 4.18 0 0 0 4.168-4.168V4.168A4.18 4.18 0 0 0 59.789 0H40.414a4.18 4.18 0 0 0-4.168 4.168v7.5a4.18 4.18 0 0 0 4.168 4.168l1.254-.004v5.418c-18.332 3.957-31.457 20-31.457 38.957C10.207 82.082 28.125 100 50 100s39.793-17.918 39.793-39.793c0-9.582-3.336-18.125-8.75-25zm-21.25-23.539v2.082zm-19.586-7.5h19.375v7.5H40.207Zm13.961 11.664v4.582h-.418c-1.25-.207-2.5-.207-3.75-.207h-.625c-1.043 0-2.082 0-3.125.207h-.418v-4.582zm-4.168 80c-19.582 0-35.625-16.043-35.625-35.625 0-17.5 12.5-32.293 29.793-35.207 1.043-.207 1.875-.207 2.918-.418h2.918c2.082 0 3.957.207 5.832.418 8.332 1.457 15.418 5.418 20.625 11.457v.207c5.625 6.25 8.957 14.582 8.957 23.543C85.625 79.793 69.582 95.832 50 95.832"
        transform="matrix(.12 0 0 .12 0 0)"
        fill="currentColor"
      />
      <path
        d="M55.207 28.543c-1.668-.207-3.543-.418-5.207-.418-1.668 0-3.543.207-5.207.418-15.625 2.5-26.875 15.832-26.875 31.664 0 17.707 14.375 32.082 32.082 32.082s32.082-14.375 32.082-32.082c0-15.832-11.25-29.164-26.875-31.664m-3.125 59.375V86.25c0-1.25-.832-2.082-2.082-2.082s-2.082.832-2.082 2.082v1.668c-13.75-1.043-24.582-12.082-25.625-25.625h1.668c1.25 0 2.082-.832 2.082-2.082s-.832-2.082-2.082-2.082h-1.668c1.043-12.707 10.418-23.332 23.125-25.418.832-.207 1.668-.207 2.5-.207v1.668c0 1.25.832 2.082 2.082 2.082s2.082-.832 2.082-2.082v-1.668c.832 0 1.668.207 2.5.207C67.5 34.793 76.875 45.418 77.707 58.129h-1.668c-1.25 0-2.082.832-2.082 2.082s.832 2.082 2.082 2.082h1.668c-.832 13.75-11.875 24.582-25.625 25.625"
        transform="matrix(.12 0 0 .12 0 0)"
        fill="currentColor"
      />
      <path
        d="M61.875 58.125h-9.793V41.668c0-1.25-.832-2.082-2.082-2.082s-2.082.832-2.082 2.082v18.543c0 1.25.832 2.082 2.082 2.082h11.875c1.25 0 2.082-.832 2.082-2.082 0-1.254-1.04-2.086-2.082-2.086"
        transform="matrix(.12 0 0 .12 0 0)"
        fill="currentColor"
      />
    </svg>
  );
}
