import i18next from "i18next";
import { initReactI18next } from "react-i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import Backend from "i18next-http-backend";
// import Backend from 'i18next-locize-backend'
// import LastUsed from 'locize-lastused'
// import { locizeEditorPlugin } from 'locize'
import { type DateStyle, formatDate } from "@/shared/utils/Dates";

// const locizeOptions = {
//   projectId: '84a865b3-0635-4db7-aa5f-e90bb4b111b1',
//   apiKey: '6087021c-595a-416c-a12a-91e4371bda1b', // YOU should not expose your apps API key to production!!!
//   referenceLng: 'fr',
//   allowedAddOrUpdateHosts: ['localhost']
// }

const fallbackLng = "fr";

const debug = import.meta.env.DEV;

await i18next
  // locize-lastused
  // sets a timestamp of last access on every translation segment on locize
  // -> safely remove the ones not being touched for weeks/months
  // https://github.com/locize/locize-lastused
  // .use(LastUsed)
  // locize-editor
  // InContext Editor of locize
  // .use(locizeEditorPlugin({ show: true }))
  .use(Backend)
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    debug,
    fallbackLng,
    ns: ["common", "mainMenu", "applicationPages"],
    defaultNS: "common",
    interpolation: {
      escapeValue: false, // not needed for react as it escapes by default
    },
    // backend: locizeOptions,
    // locizeLastUsed: locizeOptions,
    // saveMissing: true,
    // react: {
    //   bindI18n: 'languageChanged editorSaved'
    // }
  });

i18next.services.formatter?.add(
  "DATE",
  (value, lng, options: { style: DateStyle }) => {
    return formatDate(value as Date, lng ?? fallbackLng, options.style);
  },
);

export default i18next;
