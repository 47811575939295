import classNames from "classnames";
import { useState, type ReactNode } from "react";
import { Link } from "react-router-dom";
import type MainMenuBreadCrumb from "./model/MainMenuBreadCrumb";
import { getMenuTargetPage } from "./model/MainMenuFull";
import "./MenuItems.scss";
import type Menu from "./model/Menu";
import SubMenu from "./SubMenu";
import { subMenuColors } from "./styling-helpers/SubMenuColors";
import { useTranslation } from "react-i18next";
import { menuIconClass } from "./styling-helpers/MainMenuIcons";

export interface Properties {
  depth: number;
  menuItem: Menu;
  userCrumb: MainMenuBreadCrumb;
  onUserChangedCrumb: (bc: MainMenuBreadCrumb) => void;
}

export default function MainMenuItem(props: Properties): ReactNode {
  const { t } = useTranslation(["mainMenu"]);
  const [hover, setHover] = useState(false);

  const colors = subMenuColors(props.menuItem.parent);
  const iconClasses = classNames(
    "icon",
    props.menuItem.hasChildren() && "children",
    menuIconClass(props.menuItem),
  );

  return (
    <div
      className="main-menu-item"
      style={isActive() ? { backgroundColor: colors.hover } : {}}
      onMouseEnter={() => {
        setHover(true);
      }}
      onMouseLeave={() => {
        setHover(false);
        props.onUserChangedCrumb(props.userCrumb.pop());
      }}
      onMouseOver={(e) => {
        e.stopPropagation();
        props.onUserChangedCrumb(
          props.userCrumb.selectMenu(props.depth, props.menuItem),
        );
      }}
      onClick={(e) => {
        e.stopPropagation();
      }}
    >
      {props.userCrumb.isOnPath(props.depth, props.menuItem) && renderSubMenu()}
      {wrapInLink(<div className={iconClasses} />)}
      {props.userCrumb.isLastActive(props.menuItem) && hover && renderTooltip()}
    </div>
  );

  function renderTooltip(): ReactNode {
    return (
      <div className={tooltipClasses()}>
        <div className="tooltip-content">
          <div>{t(props.menuItem.key)}</div>
        </div>
      </div>
    );
  }

  function tooltipClasses(): string {
    return classNames(
      "tooltip",
      props.menuItem.hasChildren() && "push-further-right",
    );
  }

  function renderSubMenu(): ReactNode {
    return (
      <SubMenu
        depth={props.depth + 1}
        subLevel={2}
        parentMenu={props.menuItem}
        userCrumb={props.userCrumb}
        onUserChangedCrumb={props.onUserChangedCrumb}
      >
        {props.menuItem.children.map((m) => (
          <MainMenuItem
            key={m.key}
            depth={props.depth + 1}
            menuItem={m}
            userCrumb={props.userCrumb}
            onUserChangedCrumb={props.onUserChangedCrumb}
          />
        ))}
      </SubMenu>
    );
  }

  function isActive(): boolean {
    return props.userCrumb.isOnPath(props.depth, props.menuItem);
  }

  function wrapInLink(node: ReactNode): ReactNode {
    return linkToReact() ? reactLink(node) : dojoLink(node);
  }

  function linkToReact(): boolean {
    return getMenuTargetPage(props.menuItem)?.isReact() ?? false;
  }

  function reactLink(node: ReactNode): ReactNode {
    return <Link to={targetUrl()}>{node}</Link>;
  }

  function dojoLink(node: ReactNode): ReactNode {
    return (
      <Link reloadDocument to={targetUrl()}>
        {node}
      </Link>
    );
  }

  function targetUrl(): string {
    return getMenuTargetPage(props.menuItem)?.buildUrl() ?? "";
  }
}
