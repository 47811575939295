import { capitalize } from "@/shared/utils/Strings";

export enum MyCawanModule {
  // Achats/gestion
  ACHATS_GESTION,
  API_FULLSOON,
  EDI_FRANCE_FRAIS,
  EDI_PREVOGEL,
  EDI_STEF,

  // Comptabilité
  COMPTABILITE,
  REPARTITION_DE_SERVICE,
  CAISSES_CASHPAD,
  CAISSES_CSI,
  CAISSES_ETC,
  CAISSES_INNOVORDER,
  CAISSES_LADDITION,
  CAISSES_LIGHTSPEED,
  CAISSES_MEDIALOG,
  CAISSES_PI,
  CAISSES_POINTEX,
  CAISSES_TILLER,
  CAISSES_ZELTY,
  CAISSES_ZESHOP,

  // Apps
  MYCAWANSTAFF,
  POINTEUSE,

  // HACCP
  HACCP,
  SONDES_DISRUPTIVE_TECHNOLOGIES,

  // RH / Gestion du temps
  RH_GESTION_TEMPS,
  SIGNATURE_ELECTRONIQUE,
  EXPORT_PAIE_CEGEDIM,
  EXPORT_PAIE_PLEIADES,
  EXPORT_PAIE_SAGE,
  EXPORT_PAIE_SILAE,
  SYNCHRO_SAGE,
  CEGID,

  // Divers
  AUDITS,
  DOCUMENTS,
  ENERGIE,
  IMPORT_EXPORT,
}

export function i18nModuleKey(module: MyCawanModule): string {
  return `common:modules.${toCamelCaseString(module)}`;
}

function toCamelCaseString(module: MyCawanModule): string {
  const name = MyCawanModule[module].toLowerCase();
  const tokens = name.split("_");
  const first = tokens.shift();
  return [first, ...tokens.map(capitalize)].join("");
}
