import { type PropsWithChildren, useEffect, useRef } from "react";
import "./MainHeaderMenuButton.scss";
import classNames from "classnames";
import type { IconComponent } from "@/shared/components/generic/IconComponentType.ts";

export interface Properties {
  icon: IconComponent;
  tooltipText: string;
  active?: boolean;
  onClick?: (e: object) => void;
}

export function MainHeaderMenuButton(
  props: PropsWithChildren<Properties>,
): React.JSX.Element {
  return (
    <div className="main-header-menu-button">
      <_Button {...props} />
      <_DropDown {...props} />
      <_Tooltip {...props} />
    </div>
  );
}

function _Button(props: Properties): React.JSX.Element {
  return (
    <>
      <div
        className={classNames(
          "main-header-button",
          (props.active ?? false) ? "active-button" : "",
        )}
        onClick={props.onClick}
      >
        {props.icon({ width: "26px", height: "26px" })}
      </div>
      <div
        className={classNames(
          "selected",
          (props.active ?? false) ? "active" : "",
        )}
      ></div>
    </>
  );
}

function _DropDown(props: PropsWithChildren<Properties>): React.JSX.Element {
  return (
    <div
      className={classNames(
        (props.active ?? false) ? "fade-in" : "fade-out",
        "position",
      )}
    >
      {props.children}
    </div>
  );
}

function _Tooltip(props: Properties): React.JSX.Element {
  const tooltipRef = useRef<HTMLDivElement>(null);
  useEffect(() => {
    if (tooltipRef.current != null) {
      const rect: DOMRect = tooltipRef.current.getBoundingClientRect();
      const docWidth = document.documentElement.clientWidth;
      if (rect.right > docWidth) {
        tooltipRef.current.style.right = "0";
      }
    }
  });
  return (
    <div
      ref={tooltipRef}
      className={classNames(
        "tooltip",
        (props.active ?? false) ? "no-tooltip" : "",
      )}
    >
      {props.tooltipText}
    </div>
  );
}
