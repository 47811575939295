/******************************
 * GENERATED FILE, DO NOT EDIT *
 *******************************/ import type {
  ReactNode,
  SVGProps,
} from "react";
export default function IconGear(props: SVGProps<SVGSVGElement>): ReactNode {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16px"
      height="16px"
      fill="none"
      viewBox="0 0 22 22"
      color="currentColor"
      {...props}
    >
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit={10}
        strokeWidth={1.2}
        d="M18.768 11c0-.91.48-1.71 1.207-2.178.498-.32.893-.893.672-1.443a9.9 9.9 0 0 0-1.622-2.691c-.369-.438-1.035-.385-1.548-.13a2.79 2.79 0 0 1-2.594-.06 2.6 2.6 0 0 1-1.344-2.063c-.048-.592-.347-1.226-.934-1.315a10.7 10.7 0 0 0-3.21 0c-.587.09-.886.723-.934 1.315a2.6 2.6 0 0 1-1.344 2.063 2.78 2.78 0 0 1-2.593.06c-.512-.255-1.179-.308-1.548.13a10 10 0 0 0-1.622 2.69c-.221.55.173 1.124.671 1.445A2.59 2.59 0 0 1 3.232 11c0 .909-.479 1.71-1.207 2.177-.498.32-.892.895-.671 1.444a9.9 9.9 0 0 0 1.623 2.692c.369.437 1.034.384 1.547.13a2.79 2.79 0 0 1 2.593.059 2.6 2.6 0 0 1 1.345 2.064c.047.591.346 1.225.933 1.314a10.7 10.7 0 0 0 3.212 0c.586-.089.885-.722.932-1.313a2.6 2.6 0 0 1 1.344-2.065 2.79 2.79 0 0 1 2.595-.06c.512.255 1.177.308 1.546-.13a9.9 9.9 0 0 0 1.623-2.69c.22-.55-.174-1.124-.672-1.445A2.59 2.59 0 0 1 18.767 11M11 13.926c-1.672 0-3.027-1.31-3.027-2.926S9.329 8.074 11 8.074c1.67 0 3.027 1.31 3.027 2.926S12.67 13.926 11 13.926"
      />
    </svg>
  );
}
