export class UserPreferences {
  readonly displaySitesCodes: boolean;
  readonly sortSitesByCode: boolean;
  // TODO: impression couleur
  // TODO: sections préférées

  constructor(displaySitesCodes: boolean, sortSitesByCode: boolean) {
    this.displaySitesCodes = displaySitesCodes;
    this.sortSitesByCode = sortSitesByCode;
  }
}
