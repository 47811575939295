import mainMenuJson from "@/layout/sticky-column/main-menu/model/main_menu.json";
import type Menu from "@/layout/sticky-column/main-menu/model/Menu";
import { colors } from "@/shared/style/design_system/Colors.ts";

const _COLORS = new Map<string, string[]>([
  ["accueil", [colors.accueilSecondaryActive, colors.accueilSecondaryHover]],
  [
    "people_management",
    [
      colors.humanResourcesSecondaryActive,
      colors.humanResourcesTertiaryActive,
      colors.humanResourcesTertiaryHover,
    ],
  ],
  [
    "materials_management",
    [
      colors.materialsManagementSecondaryActive,
      colors.materialsManagementTertiaryActive,
      colors.materialsManagementTertiaryHover,
    ],
  ],
  [
    "haccp",
    [
      colors.haccpSecondaryActive,
      colors.haccpTertiaryActive,
      colors.haccpTertiaryHover,
    ],
  ],
  ["documents_home", []],
  [
    "compta",
    [
      colors.accountingSecondaryActive,
      colors.accountingTertiaryActive,
      colors.accountingTertiaryHover,
    ],
  ],
  ["analysis", [colors.analysisSecondaryActive, colors.analysisSecondaryHover]],
  [
    "caisses",
    [colors.cashRegistersSecondaryActive, colors.cashRegistersSecondaryHover],
  ],
  [
    "fileexchange",
    [colors.importExportSecondaryActive, colors.importExportSecondaryHover],
  ],
]);

interface MenuItemColors {
  bg: string;
  hover: string;
}

const _COLORS_MAP = buildColorsTree();

function buildColorsTree(): Map<string, MenuItemColors> {
  const res = new Map<string, MenuItemColors>();
  for (const root of mainMenuJson) {
    for (const level1 of root.children ?? []) {
      const colors = _COLORS.get(root.key);
      if (colors === undefined) continue;
      res.set(level1.key, { bg: colors[0], hover: colors[1] });
      for (const level2 of level1.children) {
        res.set(level2.key, { bg: colors[1], hover: colors[2] });
      }
    }
  }
  return res;
}

export function subMenuColors(menu?: Menu): MenuItemColors {
  const missingRed = {
    bg: "#ee0000",
    hover: "#ff4000",
  };
  return menu === undefined ? missingRed : (
      (_COLORS_MAP.get(menu.key) ?? missingRed)
    );
}
