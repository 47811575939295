import type { AppUserInfo } from "@/shared/model/user/AppUserInfo";
import type { PageAccessChecker } from "@/shared/model/user/permissions/AccessCheckersApi";

export abstract class Page {
  constructor(
    readonly key: string,
    readonly checker: PageAccessChecker,
    readonly children: Page[] = [],
  ) {}

  userCanAccess(user: AppUserInfo): boolean {
    return this.checker.check(user);
  }

  addChildPage(childPage: Page): void {
    this.children.push(childPage);
    this.checker.addChild(childPage.checker);
  }

  abstract buildUrl(): string;
  abstract isReact(): boolean;
}

export class ReactPage extends Page {
  constructor(
    readonly key: string,
    readonly route: string,
    readonly checker: PageAccessChecker,
  ) {
    super(key, checker);
  }

  buildUrl(): string {
    return this.route;
  }

  isReact(): boolean {
    return true;
  }
}

export class DojoPage extends Page {
  constructor(
    readonly key: string,
    readonly plugin: string,
    readonly cType: string,
    readonly task: string,
    readonly checker: PageAccessChecker,
    readonly queryParams?: string,
  ) {
    super(key, checker);
  }

  buildUrl(): string {
    const params = this.queryParams === undefined ? "" : `&${this.queryParams}`;
    return `/content/${this.cType}/?task=${this.task}${params}`;
  }

  isReact(): boolean {
    return false;
  }
}
