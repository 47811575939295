import "./MainHeader.scss";
import { MainHeaderMenu } from "@/layout/header/menu/MainHeaderMenu";
import { SearchBar } from "@/layout/header/searchBar/SearchBar";
import Logo from "@/layout/header/logo/Logo";
import WelcomeMessage from "@/layout/header/message/WelcomeMessage";
import type { ReactNode } from "react";
import classNames from "classnames";

export function MainHeader(): ReactNode {
  return (
    <div className={classNames("main-header", pickStageStyle())}>
      <div>
        <Logo />
        <WelcomeMessage />
      </div>
      <div>
        <SearchBar />
      </div>
      <div>
        <MainHeaderMenu />
      </div>
    </div>
  );

  function pickStageStyle(): string {
    const host = window.location.hostname;
    if (host.includes("preprod")) {
      return "preprod-stage";
    }
    if (!_isProdHost(host)) {
      return "dev-stage";
    }
    return "";
  }

  function _isProdHost(host: string): boolean {
    return ["premium.", "mobile.", "prod."].some((prefix) =>
      host.startsWith(prefix),
    );
  }
}
